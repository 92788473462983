import { useState } from "react";
import WatchListCard from "../watchListCard/watchListCard";


const wellsArray = [
    { id: '1', name: 'Diamondback wells', description: 'My collection of diamondback wells', count: 10, isActive: false },
    { id: '2', name: 'Diamondback well review', description: 'My collection of wells for upcomming diamondback meetings', count: 20, isActive: false },
    { id: '3', name: 'Low Performing wells', description: 'My lowest performing wells for tracking', count: 30, isActive: false },
    { id: '4', name: 'High performing wells', description: 'My highest performing wells for tracking', count: 40, isActive: false },
    { id: '5', name: 'ADAMS 43-6 B 802JM', description: 'Description 5', count: 50, isActive: false },
    { id: '6', name: 'ALLDALE 136-321 A 2MS', description: 'Description 6', count: 60, isActive: false },
    { id: '7', name: 'ALETHA 4 08LS', description: 'Description 7', count: 70, isActive: false },
    { id: '8', name: 'ABBIE LAINE 30XX 14HC', description: 'Description 8', count: 80, isActive: false },
    { id: '9', name: 'ALLDALE 136-321 B 3JM', description: 'Description 9', count: 90, isActive: false },
    { id: '10', name: 'A CLASSIC DASH 18 1H', description: 'Description 10', count: 100, isActive: false },
    { id: '11', name: 'A HERRING 94-93-2N 12H', description: 'Description 11', count: 110, isActive: false },
    { id: '12', name: 'ORYX ROAN STATE A 1301HWell 12', description: 'Description 12', count: 120, isActive: false },
];

const WatchWellsList = () => {

    const [wells, setWells] = useState(wellsArray);
    const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
    // const [activeWellId, setActiveWellId] = useState<string | null>(null);

    const handleCardClick = (id: string) => {
        const updatedWells = wells.map(well => {
            if (well.id === id) {
                // setActiveWellId(id);
                return {
                    ...well,
                    isActive: !well.isActive
                }
            }
            return {
                ...well,
                isActive: false
            }
        });
        setWells(updatedWells);
    };

    const handleDropdownClick = (id: string) => {
        setActiveDropdownId(prev => (prev === id ? null : id)); // Toggle dropdown
    };

    const handleCloseDropdown = () => {
        setActiveDropdownId(null);
    };

    return (
        <div className='watch-wells-list'>
            {wells.map(well => (
                <WatchListCard
                    key={well.id}
                    id={well.id}
                    title={well.name}
                    description={well.description}
                    count={well.count}
                    isActive={well.isActive}
                    isDropdownOpen={activeDropdownId === well.id}
                    onClick={handleCardClick}
                    onDropdownClick={handleDropdownClick}
                    onCloseDropdown={handleCloseDropdown}
                />
            ))}
        </div>
    );
};

export default WatchWellsList;