import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../images/expand-icon.svg';
import download from '../../../../src/images/download-icon.svg';
import GaugeChart from './charts/GaugeChart';
import Loader from '../../common/page-loader/ComponentLoader';
import NoData from '../../dashboard/components/no-data/NoData';

interface BackPressureChartProps {
    heading: string;
}

const BackPressureDetails: React.FC<BackPressureChartProps> = ({heading}) => {
    const loading = false;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const backPressureChartdiv = useRef<HTMLDivElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const hElement = document.getElementById('main-root');
    const root = useRef<any>(null);

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };
   /*  const handleClose = () => {
        //setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    }; */

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

  return (
    <>
        <ToastContainer position='top-right' autoClose={3000} />
        <div className='grid-item-text h-full graph-card well-graph-card'>
            <div className='table-header-section pie-section'>
                <div className='title'>{heading}</div>
                <div className='header-icon'>
                    <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                        <img src={annotation_plus} alt='Expand' className='img-border' />
                    </button>
                </div>
                <div className='mr-4 dropdown-container' ref={dropdownRef}>
                    <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <span className='dot'></span>
                        <span className='dot'></span>
                        <span className='dot'></span>
                    </button>
                    {isDropdownOpen && (
                    <div className='dropdown-options'>
                        <button>Actions</button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download PDF
                        </button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download CSV
                        </button>
                    </div>
                    )}
                </div>
            </div>
            <hr />
            <div id="backPressure-container" className='flex flex-wrap items-center justify-center wellChartSection backPressureChart-container-dashboard nodata'>
                    
                {loading ? (
                    <div className='flex items-center justify-center w-100'>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <GaugeChart root={root} chartID={'backPressureChartdiv'} chartRef={backPressureChartdiv} classValue={'w-[100%]'} minValue={50} maxValue={350} clockHandValue={150} clockHandName={'50 %'}/>
                    </>
                )}
                {!modalIsOpen && <NoData heading='No data found' />}
                
            </div>
        </div>
    </>    
  );
};

export default BackPressureDetails;