import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import AssetStatus from './features/asset-status/AssetStatus';
import RodLiftAnalysis from './features/rod-lift-analysis/RodLiftAnalysis/RodLiftAnalysis';
import AssetAnalysis from './features/asset-analysis/AssetAnalysis';
import GroupStatus from './features/group-status/GroupStatus';
import { GLAnalysis } from './features/gl-analysis/GLAnalysis/GLAnalysis';
import ESPAnalysis from './features/esp-analysis/ESPAnalysis';
import { Layout } from './layouts/PageLayout';
import Asset from './features/asset/components/Asset';
import NotificationDrawerItem from './features/navigation/components/NotificationDrawerItem';
import AssetWithRedux from './features/asset/components/AssetWithRedux';
import User from './features/user/user/User';
import Admin from './features/user/admin/Admin';
import UserDashboard from './features/dashboard/UserDashboard';
import AssetDetails from './features/asset-details/AssetDetails';
import GroupDashboard from './features/dashboard/GroupDashboard';
import PageNotFound from './features/page-not-found/PageNotFound';
import PageLoader from './features/common/page-loader/PageLoader';
import { getFerrariAPIBaseURL } from './utilities/BaseURLUtility';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { getXspocToken } from './features/asset/services/AssetDataService';
import InactivePage from './features/inactive-page/InactivePage';
import WellControlroomDashboard from './features/controlroom/WellControlroomDashboard';
import './App.scss';
import ControlRoomDashboard from './features/control-room-dashboard/ControlRoomDashboard';

interface JWTToken {
  userDetails: string;
}

const App: React.FC = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inactiveUser, setInactiveUser] = useState(false);

  // const handleLogout = () => {
  //   setIsUserLoggedIn(false);
  //   setIsUserAuthorized(false);
  //   const logoutUrl = process.env.REACT_APP_LOGOUT_URL || '';
  //   window.location.href = logoutUrl;
  //   localStorage.clear();
  // };

  const handleRedirect = () => {
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL || '';
    // console.log(logoutUrl);
    window.location.href = logoutUrl;
    localStorage.clear();
  };



  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const paramsObj: Record<string, string> = {};
      for (const [key, value] of searchParams.entries()) {
        paramsObj[key] = value;
      }
      const isJwtAccessTokenExist = localStorage.getItem('jwtAccessToken');
      if (isJwtAccessTokenExist === null) {
        if (!isUserLoggedIn) {
          if ('ref' in paramsObj) {
            localStorage.clear();
            console.log('process.env.REACT_REDIRECT_URL;', process.env.REACT_APP_REDIRECT_URL);
            const queryParams = {
              referenceToken: paramsObj['ref'],
            };
            const queryString = new URLSearchParams(queryParams).toString();
            const baseUrl = getFerrariAPIBaseURL();
            const getApiUrl = `${baseUrl}/GetAccessToken?${queryString}`;
            try {
              const response = await fetch(getApiUrl);

              if (response.status === 403) {
                setInactiveUser(true);
                setLoading(false);
                return;
              }

              if (!response.ok || response.status !== 200) {
                setIsUserLoggedIn(false);
                setIsUserAuthorized(false);
                setTimeout(() => {
                  handleRedirect();
                }, 3000);
                return;
              }

              const data = await response.json();
              if (data?.access_token && data?.refresh_token) {
                localStorage.setItem('jwtAccessTokenExpiry', JSON.stringify(data?.expires_in));
                localStorage.setItem('jwtUserName', data?.userName);
                localStorage.setItem('jwtAccessToken', data?.access_token);
                localStorage.setItem('jwtRefreshToken', data?.refresh_token);
                localStorage.setItem('correlationID', uuidv4());
                setIsUserAuthorized(true);
                setIsUserLoggedIn(true);
              }
              const decodedToken = jwtDecode<JWTToken>(data?.access_token);
              const loggedInUser = decodedToken.userDetails;
              localStorage.setItem('loggedInUser', loggedInUser);
            } catch (error) {
              console.error('Error fetching access token:', error);
              handleRedirect();
            }
          } else {
            if (process.env.REACT_APP_REDIRECT_URL !== undefined) {
              console.log('Test ');
              window.location.href = process.env.REACT_APP_REDIRECT_URL;
              setLoading(false);
              return;
            }
          }
        }
      } else {
        setIsUserLoggedIn(true);
        setIsUserAuthorized(true);
      }

      setLoading(false);
      // Set the timeout
      const timeoutId = setTimeout(() => {
        searchParams.delete('ref');
        window.history.replaceState({}, '', `${window.location.pathname}`);
        clearTimeout(timeoutId);
      }, 3000);
      return () => clearTimeout(timeoutId);
    };
    fetchData();
  }, [isUserLoggedIn]);

  useEffect(() => {
    const jwtAccessTokenExpiry = localStorage.getItem('jwtAccessTokenExpiry');
    const refreshTokenInterval = jwtAccessTokenExpiry ? parseInt(jwtAccessTokenExpiry) * 1000 : 45 * 60 * 1000;
    const interval = setInterval(async () => {
      const refreshToken = localStorage.getItem('jwtRefreshToken');
      if (!refreshToken) {
        setIsUserLoggedIn(false);
        setIsUserAuthorized(false);
        setTimeout(() => {
          handleRedirect();
        }, 3000);
        return;
      }
      const queryParams = { refreshToken };
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('jwtAccessToken')}`,
        'Content-Type': 'application/json',
      };
      const queryString = new URLSearchParams(queryParams).toString();
      const baseUrl = getFerrariAPIBaseURL();
      const getApiUrl = `${baseUrl}/GetRefreshToken?${queryString}`;
      try {
        const response = await fetch(getApiUrl, { headers });

        if (response.status === 403) {
          setInactiveUser(true);
          setIsUserLoggedIn(false);
          setIsUserAuthorized(false);
          setLoading(false);
          return;
        }

        if (!response.ok || response.status !== 200) {
          setIsUserLoggedIn(false);
          setIsUserAuthorized(false);
          setTimeout(() => {
            handleRedirect();
          }, 3000);
          return;
        }
        const data = await response.json();
        if (data?.access_token && data?.refresh_token) {
          localStorage.setItem('jwtAccessToken', data?.access_token);
          localStorage.setItem('jwtRefreshToken', data?.refresh_token);
          localStorage.setItem('jwtAccessTokenExpiry', JSON.stringify(data?.expires_in));
          setIsUserAuthorized(true);
          setIsUserLoggedIn(true);
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        setIsUserLoggedIn(false);
        setIsUserAuthorized(false);
        setTimeout(() => {
          handleRedirect();
        }, 3000);
      }
    }, refreshTokenInterval);

    return () => clearInterval(interval);
  }, []);

  //to refresh the xspoc token
  useEffect(() => {
    if (isUserLoggedIn && isUserAuthorized) {
      getXspocToken();
    }
    const refreshTokenInterval = 10 * 60 * 1000; // Refresh token every 10 minutes
    const interval = setInterval(() => {
      if (isUserLoggedIn && isUserAuthorized) {
        getXspocToken();
      }
    }, refreshTokenInterval);

    return () => clearInterval(interval);
  }, [isUserLoggedIn, isUserAuthorized]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {inactiveUser ? (
          <Route path='/' element={<InactivePage />} />
        ) : (
          isUserLoggedIn && isUserAuthorized ? (
            <Route path='/' element={<Layout />}>
              <Route path='' element={<UserDashboard />} />
              <Route path='/layout/group-dashboard' element={<GroupDashboard />} />
              <Route path='asset/' element={<AssetWithRedux />} />
              <Route path='/layout/asset/' element={<Asset />}>
                <Route path='/layout/asset/status/:id' element={<AssetStatus />} />
                <Route path='/layout/asset/analysis/:id' element={<AssetAnalysis />} />
              </Route>
              <Route path='/layout/rodliftanalysis/:assetId' element={<RodLiftAnalysis />} />
              <Route path='/layout/group/status' element={<GroupStatus />} />
              <Route path='/layout/espanalysis/:id' element={<ESPAnalysis />} />
              <Route path='/layout/performancecurve' element={<GLAnalysis />} />
              <Route path='/layout/user' element={<User />} />
              <Route path='/layout/admin' element={<Admin />} />
              <Route path='/layout/assets' element={<AssetDetails />} />
              <Route path='/layout/notification' element={<NotificationDrawerItem />} />
              <Route path='/layout/well-control-room' element={<WellControlroomDashboard />} />
              <Route path='/layout/control-room' element={<ControlRoomDashboard />} />
            </Route>
          ) : (
            <Route path='*' element={<PageNotFound />} />
          )
        )}
      </Routes>
      <Outlet />
    </BrowserRouter>
  );
};

export default App;
