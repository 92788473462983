import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { clearAssetEventSchedule, clearAssetListErrorMessage, fetchAssetListById } from '../../DashboardSlice';
import { AppUser } from '../../../user/model/AppUser';
import { updateAssetList, updateSelectedAsset } from '../../../navigation/AssetGroupsSlice';
import { useNavigate } from 'react-router-dom';
import './AssetList.scss';
import NoData from '../no-data/NoData';
import Loader from '../../../common/page-loader/ComponentLoader';
import axios from 'axios';
import { setFilterTerm } from './AlarmSlice';
import { toast } from 'react-toastify';
import { ConvertTimeToLocal } from '../../../../utilities/CommonFunctions';
import { ReactComponent as SortIconDefault} from '../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp} from '../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown} from '../../../../images/Chevron-down-icon.svg';

interface AssetEventSchedule {
  wellName: string;
  lastGoodScan: string;
  alarms: string[];
  runStatus: string;
  comms: string;
  speed: string;
  current: string;
  motorTemp: string;
  intakePressure: string;
  tubingPressure: string;
  vibration: string;
  driveLoad: string;
  [key: string]: string | string[];
}

type SortableKeys = keyof AssetEventSchedule;
interface TabsProps {
  groupName?: string;
}

export const showToaster = (message: string, type: 'success' | 'error' | 'warning' | 'info') => {
  toast.dismiss();
  toast.clearWaitingQueue();
  toast(message, {
    type,

  })
}

const AssetList: React.FC<TabsProps> = ({ groupName }) => {
  const navigate = useNavigate();
  const loggedInUser = useAppSelector((state) => state?.user?.loggedInUser);
  const storedUserData = localStorage.getItem('loggedInUser');
  const activeTab = sessionStorage.getItem('activeTabIndex');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const initialUserState = loggedInUser || storedUser || undefined;
  const dispatch = useAppDispatch();
  const assetEventSchedule = useAppSelector((state) => state.dashboard.assetEventSchedule || []);
  const assetEventTotalCount = useAppSelector((state) => state.dashboard.assestEventTotalCount);
  const listLoading = useAppSelector((state) => state.dashboard.assetListLoading);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: 'asc' | 'desc' } | null>({
    key: 'wellName',
    direction: 'asc',
  });
  const filterTerm = useAppSelector((state) => state?.alarms.filterTerm);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const cancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
  const errorMessage = useAppSelector((state) => state.dashboard.assetListErrorMessage);

  useEffect(() => {
    if (initialUserState?.id && groupName) {
      setLoading(true);
      dispatch(setFilterTerm(""));
      cancelTokenSource.current = axios.CancelToken.source();
      dispatch(clearAssetEventSchedule());
      setTimeout(() => {
        dispatch(fetchAssetListById({
          userId: initialUserState.id, groupName, startIndex: 0, endIndex: 25,
          searchQuery: filterTerm ? "?filter=" + filterTerm : ""
          , cancelToken: cancelTokenSource?.current?.token
        })).then(() => {
          setLoading(false)
        })

      },);
    }

    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('Component unmounted or route changed.');
      }
    }
  }, [dispatch, groupName, initialUserState?.id]);


  useEffect(() => {
    if (assetEventSchedule.length == assetEventTotalCount) {
      setAllDataLoaded(true);
    } else {
      setAllDataLoaded(false);
    }
  }, [assetEventSchedule.length])

  const getValueByKey = (item: AssetEventSchedule, key: keyof AssetEventSchedule): string => {
    const value = item[key];
    if (value === null || value === undefined) {
      return ''; // Return empty string if value is null or undefined
    }
    if (Array.isArray(value)) {
      return value.join(', '); // Convert arrays to a string for comparison
    }
    return value.toString(); // Convert non-null values to string to ensure safe operations
  };

  const sortedAssetEventSchedule = useMemo(() => {
    const sortableItems = [...assetEventSchedule];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = getValueByKey(a as unknown as AssetEventSchedule, sortConfig.key);
        const bValue = getValueByKey(b as unknown as AssetEventSchedule, sortConfig.key);
        return aValue.toLowerCase().localeCompare(bValue.toLowerCase()) * (sortConfig.direction === 'asc' ? 1 : -1);
      });
    }
    return sortableItems;
  }, [assetEventSchedule, sortConfig]);

  const observer = useRef<IntersectionObserver>();
  const assetlistTable = document.getElementById('assetlistTable');
  const lastDocumentElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || allDataLoaded) return;
      if (observer.current) observer.current.disconnect();
      if (errorMessage) {
        if (assetlistTable) {
          assetlistTable.scrollTop = assetlistTable.scrollTop - 100;
        }
        showToaster(errorMessage, 'error');
        dispatch(clearAssetListErrorMessage());
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && initialUserState && groupName && assetEventTotalCount >= assetEventSchedule.length) {
          setLoading(true);
          if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Operation canceled due to new request.');
          }
          cancelTokenSource.current = axios.CancelToken.source();
          dispatch(fetchAssetListById({
            userId: initialUserState.id,
            groupName,
            startIndex: assetEventSchedule?.length + 1,
            endIndex: assetEventSchedule?.length + 25,
            searchQuery: filterTerm ? `?filter=${filterTerm}` : "",
            cancelToken: cancelTokenSource.current.token
          }))
            .then(() => {
              setLoading(false);
            })
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, assetEventSchedule?.length, groupName, initialUserState, allDataLoaded],
  );

  const useDebouncedEffect = (effect: React.EffectCallback, deps: any[], delay: number) => {
    const firstRender = useRef(true);

    useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }

      const handler = setTimeout(() => {
        effect();
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [...deps, delay]);
  };

  useDebouncedEffect(() => {
    setLoading(true);
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel('Operation canceled due to new request.');
    }
    cancelTokenSource.current = axios.CancelToken.source();
    dispatch(clearAssetEventSchedule())
    setTimeout(() => {
      if (cancelTokenSource && initialUserState && groupName && assetEventTotalCount >= assetEventSchedule.length) {
        setAllDataLoaded(false);
        dispatch(fetchAssetListById({
          userId: initialUserState.id,
          groupName,
          startIndex: 0,
          endIndex: 25,
          searchQuery: filterTerm ? "?filter=" + filterTerm : "",
          cancelToken: cancelTokenSource?.current?.token,
        })).then(() => {
          setLoading(false);
        })
      }
    },);


  }, [filterTerm], 1000);

  const requestSort = (key: SortableKeys) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleWellName = (wellName: string, assetID: string) => {
    window.scrollTo(0, 0);
    if (activeTab !== '0') {
      sessionStorage.setItem('activeTabIndex', '0');
    }

    const groupList = sortedAssetEventSchedule?.map((well) => ({
      assetId: well?.assetID,
      assetName: well?.wellName,
      industryApplicationId: 4,
    }));

    const selectedAsset = {
      assetName: wellName,
      assetGroupName: 'Well Groups',
      assetId: assetID,
      searchString: undefined,
    };
    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupList) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupList));
    navigate(`/layout/assets/?tab=details&wellName=${wellName}`);
  };


  return (
    <div className='w-100 p-5'>
      <div className='asset-list__card relative'>
        {listLoading && (
          <div className={`flex items-center justify-content asset-list__loader-margin relative ${loading ? 'loader-opacity' : ''}`}>
            <Loader />
          </div>
        )}
        {assetEventSchedule?.length && (
          <div className={`asset-list__table ${loading || listLoading ? 'table-opacity' : ''}`} >
            <div className='asset-list__header'>
              <div className='asset-list__row'>
                <div className='asset-list__cell-one head' onClick={() => requestSort('wellName')}>
                  Name{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'wellName' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('api')}>
                  API{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'api' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('longitude')}>
                  Lat/Long{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'longitude' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one lat' onClick={() => requestSort('lastGoodScan')}>
                  Last good scan{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'lastGoodScan' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('operator')}>
                  Operator{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'operator' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('route_Field')}>
                  Route/Field{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'route_Field' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('alarms')}>
                  Alarms{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'alarms' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('runStatus')}>
                  Run status{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'runStatus' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('frequency')}>
                  Frequency{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'frequency' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('motorTemp')}>
                  Motor temp
                  <span className='sort-icon'>
                    {sortConfig?.key === 'motorTemp' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('motorCurrent')}>
                  Motor current
                  <span className='sort-icon'>
                    {sortConfig?.key === 'motorCurrent' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}{' '}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('casingPressure')}>
                  Casing pressure{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'casingPressure' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('tubingPressure')}>
                  Tubing pressure{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'tubingPressure' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('intakePressure')}>
                  Intake pressure
                  <span className='sort-icon'>
                    {sortConfig?.key === 'intakePressure' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('intakeTemperature')}>
                  Intake temp
                  <span className='sort-icon'>
                    {sortConfig?.key === 'intakeTemperature' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('powerConsumption')}>
                  Power consumption
                  <span className='sort-icon'>
                    {sortConfig?.key === 'powerConsumption' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('incomingHarmonic')}>
                  Incoming harmonic
                  <span className='sort-icon'>
                    {sortConfig?.key === 'incomingHarmonic' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('outputHarmonic')}>
                  Output harmonic
                  <span className='sort-icon'>
                    {sortConfig?.key === 'outputHarmonic' ? (
                        sortConfig.direction === 'asc' ? (
                          <SortIconUp alt='sort-asc' className='sort-img' />
                        ) : (
                          <SortIconDown alt='sort-dsc' className='sort-img' />
                        )
                      ) : (
                        <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                      )}
                  </span>
                </div>
              </div>
            </div>
            <div id="assetlistTable" className='asset-list__main-content'>
              {sortedAssetEventSchedule?.map((event, index) => (
                <div className='asset-list__row' ref={index + 1 == sortedAssetEventSchedule?.length ? lastDocumentElementRef : null}>
                  <div
                    key={index}
                    className='asset-list__cell-two'
                    onClick={() => handleWellName(event.wellName ?? '', event?.assetID ?? '')}
                  >
                    {event.wellName ?? '-'}
                  </div>
                  <div className='asset-list__cell'>{event.api ?? '-'}</div>
                  <div className='asset-list__cell'>
                    {event.latitude}{' '} {event.longitude ?? '-'}
                  </div>
                  <div className='asset-list__cell lat'>{event?.lastGoodScan ? ConvertTimeToLocal(event?.lastGoodScan) : "-"}</div>
                  <div className='asset-list__cell'>{event.operator ?? '-'}</div>
                  <div className='asset-list__cell'>{event.route_Field ?? '-'}</div>
                  <div className='asset-list__cell'>{event.alarms ?? '-'}</div>
                  <div className='asset-list__cell'>{event.runStatus ?? '-'}</div>
                  <div className='asset-list__cell'>{event.frequency ?? '-'}</div>
                  <div className='asset-list__cell'>{event.motorTemp ?? '-'}</div>
                  <div className='asset-list__cell'>{event.motorCurrent ?? '-'}</div>
                  <div className='asset-list__cell'>{event.casingPressure ?? '-'}</div>
                  <div className='asset-list__cell'>{event.tubingPressure ?? '-'}</div>
                  <div className='asset-list__cell'>{event.intakePressure ?? '-'}</div>
                  <div className='asset-list__cell'>{event.intakeTemperature ?? '-'}</div>
                  <div className='asset-list__cell'>{event.powerConsumption ?? '-'}</div>
                  <div className='asset-list__cell'>{event.incomingHarmonic ?? '-'}</div>
                  <div className='asset-list__cell'>{event.outputHarmonic ?? '-'}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div style={{ display: (listLoading || loading) || assetEventSchedule?.length ? 'none' : 'block' }}>
          <NoData heading='No data found' />
        </div>
      </div>
    </div>
  );
};

export default AssetList;
