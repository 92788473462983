import React, { memo, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../images/expand-icon.svg';
import download from '../../../../src/images/download-icon.svg';
import NoData from '../../dashboard/components/no-data/NoData';
import Loader from '../../common/page-loader/ComponentLoader';
import GaugeChart from './charts/GaugeChart';
import SpeedChartModel from './modals/SpeedChartModel';

interface SpeedChartProps {
  heading: string;
}

const SpeedDetails: React.FC<SpeedChartProps> = ({ heading }) => {
  const loading = false;  // Set loading based on your data fetching logic
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const speedHzChartdiv = useRef<HTMLDivElement | null>(null);
  const speedRpmChartdiv = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const hElement = document.getElementById('main-root');
  const root = useRef<any>(null);
  const modalHeading = 'Speed';

  const openModal = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setModalIsOpen(true);
    hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
     setModalIsOpen(false);
     hElement && hElement?.classList.remove('modal-open');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} />
      <div className='grid-item-text h-full graph-card well-graph-card'>
        <div className='table-header-section pie-section'>
          <div className='title'>{heading}</div>
          <div className='header-icon'>
            <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
              <img src={annotation_plus} alt='Expand' className='img-border' />
            </button>
          </div>
          <div className='mr-4 dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button className={`btn btn-default cancelSelectorName`}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
                <button className={`btn btn-default cancelSelectorName`}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download CSV
                </button>
              </div>
            )}
          </div>
        </div>
        <hr />
        <div id="speedChart-container" className='flex flex-wrap items-center justify-center wellChartSection speedChart-container-dashboard nodata'>

          {loading ? (
            <div className='flex items-center justify-center w-100'>
              <Loader />
            </div>
          ) : (
            <>
              {/* <div id={`speedchartdiv`} ref={speedHzchartdiv} className='chart-wrapper'></div> */}
              <GaugeChart root={root} chartID={'speedHzChartdiv'} chartRef={speedHzChartdiv} classValue={'w-[50%]'} minValue={50} maxValue={350} clockHandValue={100} clockHandName={'HZ'} />
              <GaugeChart root={root} chartID={'speedRpmChartdiv'} chartRef={speedRpmChartdiv} classValue={'w-[50%]'} minValue={50} maxValue={350} clockHandValue={200} clockHandName={"RPM"} />
            </>
          )}
          {!modalIsOpen && <NoData heading='No data found' />}

        </div>
      </div>
      <SpeedChartModel
          isOpen={modalIsOpen}
          onRequestClose={handleClose}
          heading={modalHeading}
        />
    </>
  );
};

export default memo(SpeedDetails);