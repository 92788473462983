
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import LineChart from './LineChart';
import { HistoryDetailsProps, SparkLineFilterProps, TimeSeriesChannelDataProps } from './HistoryDetails';
import { fetchAssetTimeseriesChannelsData, fetchMasterVariablesByWellname } from './HistorySlice';
import './History.scss';
import { defaultStaticRanges, defineds, formateDate } from '../../../common/DateRangeSelector/DefaultRanges';
import calenderIcon from '../../../../images/calendar-icon.svg';
import filterIcon from '../../../../images/filter-icon.svg';
import downIcon from '../../../../images/chevron-down.svg';
import searchIcon from '../../../../images/search-icon.svg';
import { addDays } from 'date-fns';
import { useDetectClickOutside } from 'react-detect-click-outside';
import DateRangeSelector from '../../../common/DateRangeSelector/DateRangeSelector';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import { fetchTrendsChartFilterData } from '../trends/TrendsSlice';
import { ToastContainer } from 'react-toastify';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';
import { showToaster } from '../../../dashboard/components/asset-location/AssetList';

interface CardsDesignProps {
  trend: HistoryDetailsProps[] | any;
  index: number;
  selectedDate: {
    startDate: Date | null,
    endDate: Date | null
  }
}

interface FilterValue {
  paramTypeId: string,
  channelId: string
}
const CardsDesign: React.FC<CardsDesignProps> = ({ trend, index, selectedDate }) => {
  const unitOfMeasure = trend?.unitOfMeasure;
  const colorCombination: any = {
    'System frequency': '#FEC84B',
    'Vibration Y': '#FFD072',
    'DC bus voltage': '#006DFA',
    'Drive voltage': '#12B76A',
    'Vibration X': '#3A7E73',
    'Intake pressure': '#004EB3',
    'Motor temperature': '#F97066',
    'Intake temperature': '#F04438',
    'Casing pressure': '#6BBBAE',
    'Tubing pressure': '#0094BD',
    'Motor current': '#FB743C',
    'Downhole current': '#F97066',
    'System RPM': '#B8C5CC',
  };

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='title'>{trend[0]?.unitOfMeasure}</div>
        </div>

        <div className='card-body'>
          <div className='left-container-history'>
            {trend?.map((item: any) => {
              return (
                <div style={{ margin: '8px 0' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        marginRight: '4px',
                        background: colorCombination[item?.trendName] ? colorCombination[item?.trendName] : 'red',
                      }}
                    ></div>
                    <div>{item?.trendName}</div>
                  </div>
                  <div
                    style={{ marginLeft: '20px' }}
                  >{`${+Number(item?.medean).toFixed(3) || '0'} ${item?.short_UnitOfMeasure?.toUpperCase()}`}</div>
                </div>
              );
            })}
          </div>
          <div className='rigth-container' >
            <LineChart chartName={trend?.[0]?.trendName} unitOfMeasure={unitOfMeasure} chartData={trend} colorCombination={colorCombination} index={index} short_UnitOfMeasure={trend[0]?.short_UnitOfMeasure} selectedDate={selectedDate} />
          </div>
        </div>
      </div>
    </>
  );
};

const NoDataCardsDesign: React.FC<any> = ({ trend }) => {
  const colorCombination: any = {
    'System frequency': '#FEC84B',
    'Vibration Y': '#FFD072',
    'DC bus voltage': '#006DFA',
    'Drive voltage': '#12B76A',
    'Vibration X': '#3A7E73',
    'Intake pressure': '#004EB3',
    'Motor temperature': '#F97066',
    'Intake temperature': '#F04438',
    'Casing pressure': '#6BBBAE',
    'Tubing pressure': '#0094BD',
    'Motor current': '#FB743C',
    'Downhole current': '#F97066',
    'System RPM': '#B8C5CC',
  };
  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='title'>{trend[0]?.unitOfMeasure}</div>
        </div>

        <div className='card-body' style={{ height: '200px' }}>
          <div className='left-container-history'>
            {trend?.map((item: any) => {
              return (
                <div style={{ margin: '8px 0' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        marginRight: '4px',
                        background: colorCombination[item?.trendName] ? colorCombination[item?.trendName] : 'red',
                      }}
                    ></div>
                    <div>{item?.description}</div>
                  </div>
                  {/* <div
                    style={{ marginLeft: '20px' }}
                  >{`${item?.medean} ${item?.short_UnitOfMeasure?.toUpperCase()}`}</div> */}
                </div>
              );
            })}
          </div>
          <div className='rigth-container'>
            <NoData heading='No data found' />
            {/* <LineChart chartName={trend?.[0]?.trendName} unitOfMeasure={unitOfMeasure} chartData={trend} colorCombination={colorCombination} index={index} short_UnitOfMeasure={trend[0]?.short_UnitOfMeasure} selectedDate={selectedDate} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

const SparklineChart = () => {
  const dispatch = useAppDispatch();
  const { histories, sparkLineFilterData, timeSeriesChannelDataLoading, sparkLineFilterLoading } = useAppSelector((state) => state.history);
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const [historiesData, setHistoriesData] = useState<HistoryDetailsProps[] | undefined>([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [noData, setNoData] = useState<any>([]);
  const [searchInputVal, setSearchInputVal] = useState<string>('');
  const { trendsFilterData } = useAppSelector((state) => state.trends);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: addDays(new Date(), -6),
    endDate: new Date(),
    key: 'selection',
  });
  const [filterUomData, setFilterUomData] = useState<any>([]);
  const [filteredValues, setFilteredValues] = useState<FilterValue[]>([]);
  const [prevFilteredValues, setPrevFilteredValues] = useState<FilterValue[]>([]);
  const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>([]);
  const [filterExpand, setFilterExpand] = useState<string>('');
  const assetControlData = useAppSelector((state) => state?.assetControl)


  const loading = timeSeriesChannelDataLoading || sparkLineFilterLoading

  const filteredChannelId = filteredValues?.map(item => item.channelId)
  const prevFilteredChannelId = prevFilteredValues?.map(item => item.channelId)
  const isFilterChanged = prevFilteredChannelId?.some((item) => !filteredChannelId?.includes(item)) || filteredChannelId?.some((item) => !prevFilteredChannelId?.includes(item));


  const filterNoData = () => {
    return sparkLineFilterData?.filter((item: any) => {
      if (item?.unitOfMeasure === 'None') return false;
      return filteredValues?.some(filter => {
        return filter?.channelId === item?.channelId
      })
    })
  }
  const noDataPrepare = () => {
    const filteredNoData = filterNoData();
    const groupedData = prepareData(filteredNoData);
    setNoData(groupedData ?? []);
  }




  const prepareData = (response: SparkLineFilterProps[] | TimeSeriesChannelDataProps[]) => {
    const data: any = {};
    response?.forEach((obj) => {
      if (data[obj?.unitOfMeasure]) {
        data[obj?.unitOfMeasure].push(obj);
      } else {
        if (obj?.unitOfMeasure?.toLowerCase() !== 'none' && obj?.unitOfMeasure) {
          data[obj?.unitOfMeasure] = [obj];
        }
      }
    });

    const finalData = [];

    for (const x in data) {
      finalData.push(data[x]);
    }

    return finalData;
  };
  const assetRef = useRef<string | undefined>('')
  useEffect(() => {
    if (assetRef.current !== selectedAsset.selectedAssetName) {
      setFilteredValues([])
      setPrevFilteredValues([])
      const wellName = selectedAsset?.selectedAssetName ?? '';
      dispatch(fetchMasterVariablesByWellname({ wellName: wellName }))

      if (!trendsFilterData) {
        dispatch(fetchTrendsChartFilterData())
      }
      assetRef.current = selectedAsset?.selectedAssetName
    }
  }, [selectedAsset.selectedAssetName])

  useEffect(() => {
    if (sparkLineFilterData?.length !== 0 && trendsFilterData?.length !== 0) {
      const data = prepareData(sparkLineFilterData);
      setFilterUomData(data)

      const filteredArray: FilterValue[] = []
      trendsFilterData?.map((filterData: any) => {
        const matchingOne = sparkLineFilterData?.find((item: any) => {
          return (filterData?.paramStandardType === item?.paramStandardType?.legacyId.ParamStandardTypesId && item.unitOfMeasure.toLowerCase() !== 'none')
        })
        if (matchingOne) {
          filteredArray.push({
            paramTypeId: matchingOne.paramStandardType?.legacyId.ParamStandardTypesId,
            channelId: matchingOne?.channelId
          })
        }
      })

      setFilteredValues(filteredArray)
      setPrevFilteredValues(filteredArray)
      const channelIds = filteredArray.map(item => item.channelId);
      setSelectedChannelIds(channelIds)

    } else {
      setFilterUomData([])
      setFilteredValues([])
      setPrevFilteredValues([])
      setSelectedChannelIds([])
    }

  }, [sparkLineFilterData])

  useEffect(() => {
    if (selectedChannelIds?.length !== 0) {
      const wellName = selectedAsset?.selectedAssetName ?? '';
      const startDate = formateDate(selectedDateRange.startDate);
      const endDate = formateDate(selectedDateRange.endDate);
      getTimeSeriesChannelData(wellName, startDate, endDate, selectedChannelIds.toString())
    }

    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false))

  }, [selectedChannelIds, assetControlData?.assetControlScanStatus === true])
  useEffect(() => {
    if (filteredValues) {
      const channelIds = filteredValues.map(item => item.channelId);
      setSelectedChannelIds(channelIds)
    }
  }, [selectedDateRange])
  useEffect(() => {
    if (histories && histories?.length !== 0) {

      const timeSeriesArray: TimeSeriesChannelDataProps[] = []
      histories?.map((historyData: HistoryDetailsProps) => {
        const matchedData = sparkLineFilterData?.find((filterData: any) => filterData?.channelId === historyData?.trendName && filterData?.unitOfMeasure.toLowerCase() !== 'none')

        if (matchedData) {
          const matchedDefaultTrend = trendsFilterData?.find((item: any) => matchedData.paramStandardType?.legacyId.ParamStandardTypesId === item.paramStandardType)
          const timeSeriesData = { ...historyData, channelId: matchedData.channelId, trendName: matchedDefaultTrend ? matchedDefaultTrend.name : matchedData.description, unitOfMeasure: matchedData.unitOfMeasure, short_UnitOfMeasure: matchedData.short_UnitOfMeasure }
          timeSeriesArray.push(timeSeriesData)
        }
      })
      const data = prepareData(timeSeriesArray) ?? []
      setHistoriesData(data)
    } else {
      noDataPrepare()
      setHistoriesData([])
    }
  }, [histories, loading === false])

  const getTimeSeriesChannelData = (wellName: string, startDate: string, endDate: string, channelIds: string) => {
    dispatch(fetchAssetTimeseriesChannelsData({ wellName: wellName, startDate: startDate, endDate: endDate, channelIds: channelIds }))
  }

  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'range-calendar-input') return;
    if (showCalendar) {
      setShowCalendar(!showCalendar);
    }
  };

  const handleFilterOutClick = (e: any) => {

    if (e.target.id === 'delete-btn') {
      return
    }
    if (showFilterPopup) {
      setShowFilterPopup(false);
      setFilteredValues([...prevFilteredValues])
      setFilterExpand('')
      setSearchInputVal('')
      const uomData = prepareData(sparkLineFilterData) ?? [];
      setFilterUomData(uomData)
    }
  };

  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });
  const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });

  const handleClearButton = () => {
    setFilteredValues([])
    setSearchInputVal('')
    const uomData = prepareData(sparkLineFilterData) ?? [];
    setFilterUomData(uomData)
  };

  const handleApplyButton = async () => {
    if (filteredValues?.length === 0) {
      showToaster('Please select atleast one filter', 'error');
      return;
    }
    else {
      const channelIds = filteredValues.map(item => item.channelId);

      if (channelIds?.every(value => selectedChannelIds?.includes(value))) {
        if (histories && histories?.length !== 0) {

          const timeSeriesArray: TimeSeriesChannelDataProps[] = []
          histories?.map((historyData: HistoryDetailsProps) => {
            const matchedData = sparkLineFilterData?.find((filterData: any) => filterData?.channelId === historyData?.trendName && filterData?.unitOfMeasure.toLowerCase() !== 'none' && channelIds.includes(filterData?.channelId))
            if (matchedData) {
              const matchedDefaultTrend = trendsFilterData?.find((item: any) => matchedData.paramStandardType?.legacyId.ParamStandardTypesId === item.paramStandardType)
              const timeSeriesData = { ...historyData, channelId: matchedData.channelId, trendName: matchedDefaultTrend ? matchedDefaultTrend.name : matchedData.description, unitOfMeasure: matchedData.unitOfMeasure, short_UnitOfMeasure: matchedData.short_UnitOfMeasure }
              timeSeriesArray.push(timeSeriesData)
            }
          })
          const data = prepareData(timeSeriesArray) ?? []
          setHistoriesData(data)
        } else {
          setHistoriesData([])
          noDataPrepare()
        }
      } else {
        setSelectedChannelIds(channelIds)
      }


      setPrevFilteredValues([...filteredValues])
    }
    setShowFilterPopup(false)
    setFilterExpand('')
  };


  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const matchedFilter = filteredValues.find(item => item.channelId === value)
    if (matchedFilter) {
      const updatedFilter = filteredValues.filter(item => item.channelId !== value)
      setFilteredValues(updatedFilter)
    } else {
      const matchedData = sparkLineFilterData?.find((filterData: any) => filterData?.channelId === value && filterData?.unitOfMeasure.toLowerCase() !== 'none')
      const updatedFilter = [...filteredValues, { paramTypeId: matchedData?.paramStandardType?.legacyId.ParamStandardTypesId, channelId: matchedData?.channelId }]
      setFilteredValues(updatedFilter)
    }
  };
  const handleSearch = (e: any) => {
    const val = e.target.value;

    setSearchInputVal(val);

    if (val === '') {
      const uomData = prepareData(sparkLineFilterData) ?? [];
      setFilterUomData(uomData)
    } else {
      const filteredSparkLineFilterData = sparkLineFilterData?.filter((item: any) => {
        return (item?.unitOfMeasure?.toLowerCase().includes(val?.toLowerCase()) || item?.description?.toLowerCase().includes(val?.toLowerCase()))
      }) ?? []
      const prepearedUom = filteredSparkLineFilterData?.length ? prepareData(filteredSparkLineFilterData) : [];
      setFilterUomData(prepearedUom)
    }
  };


  const renderdateRange = () => {
    return (
      <div className='history-container-date-range'>
        <div className='input-group'>
          <img src={calenderIcon} alt='calednar-icon' className='absolute top-3 left-3' />
          <input
            id='range-calendar-input'
            className='date-range-input'
            value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
            onClick={() => setShowCalendar(!showCalendar)}
          />
        </div>
        <div ref={ref}>
          {showCalendar && (
            <DateRangeSelector setShowCalendar={setShowCalendar} setSelectedDateRange={setSelectedDateRange} staticRanges={defaultStaticRanges} minDate={defineds?.startOfLastYear} maxDate={defineds?.endOfToday} selectedDateRange={selectedDateRange} />)}
        </div>

        <div style={{ position: 'relative', zIndex: '8' }}>
          <div
            id='filter-btn'
            style={{
              border: showFilterPopup
                ? '1px solid var(--Dark-mode-400, #4A5463)'
                : '1px solid var(--Dark-mode-400, #4A5463)',
              background: showFilterPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (isFilterChanged) {
                setFilteredValues([...prevFilteredValues]);
              }
              setShowFilterPopup(!showFilterPopup);
              if (showFilterPopup)
                setFilterExpand('')
            }}         >
            <img src={filterIcon} alt='filterIcon' />
            Filter {`(${filteredValues?.length})`}
            <img src={downIcon} className={!showFilterPopup ? '' : 'rotate-180'} alt='upIcon' />
          </div>

          {showFilterPopup && (
            <div className='histry-filter-modal-container' ref={filterRef}>
              <div className='trends-filter-modal-header'>Edit trend group</div>

              <div className='trends-filter-search history-search relative'>
                <img src={searchIcon} placeholder='Search for trend' alt='search' className='history-search-icon' />
                <input
                  type='text'
                  value={searchInputVal}
                  placeholder='Search for trend group'
                  onChange={handleSearch}
                  className='history-filter-search'
                />
              </div>

              <div className='history-filter-modal-body' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <div className='history-body-container'>
                  {filterUomData?.map((history: any) => {
                    return (
                      <>
                        <div className='history-body-trends' style={{ cursor: 'pointer' }} onClick={() => history[0]?.unitOfMeasure === filterExpand ? setFilterExpand('') : setFilterExpand(history[0]?.unitOfMeasure)}>
                          {history[0]?.unitOfMeasure}
                          <img src={downIcon} className={history[0]?.unitOfMeasure !== filterExpand ? '' : 'rotate-180'} alt='upIcon' />
                        </div>
                        {
                          filterExpand === history[0]?.unitOfMeasure && history.map((item: any) => {
                            return (
                              <div className='history-body-trends' style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <div className='checkbox-main-container'>
                                  <label className='checkbox-container'>
                                    <input
                                      type='checkbox'
                                      id='showCheckbox'
                                      checked={filteredValues?.find((filterItem: any) => filterItem.channelId === item?.channelId) !== undefined}
                                      onChange={handleCheckbox}
                                      value={item?.channelId}
                                    />
                                    <span className='checkbox-checkmark'></span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item?.description}&nbsp;
                                  </label>
                                </div>
                              </div>
                            )
                          })
                        }


                      </>
                    );
                  })}
                </div>

              </div>
              <div className='trends-filter-modal-footer'>
                <button
                  className={'footer-btn'}
                  onClick={handleClearButton}
                >
                  Clear
                </button>
                <button
                  className={'footer-btn'}
                  onClick={handleApplyButton}
                >
                  Apply
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };


  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={3000}
      />
      <div
        className='trends-container'
        style={{ position: 'relative', minHeight: showCalendar || showFilterPopup ? '600px' : '300px' }}
      >
        {renderdateRange()}
        <div style={{ width: '100%' }}>
          {(loading) ? (
            <div className='spark-line-loader flex items-center justify-content loader-margin'>
              <Loader />
            </div>
          ) : (historiesData && historiesData.length) ? (
            historiesData?.map((history, index) => {
              return <CardsDesign key={`${history?.date}-index`} trend={history} index={index} selectedDate={{ startDate: selectedDateRange?.startDate, endDate: selectedDateRange?.endDate }} />;
            })
          ) : (!historiesData?.length && !loading) && <div className='flex flex-column items-center justify-center'>
            {noData?.map((data: any) => {
              return <NoDataCardsDesign key={`${data?.address}-index`} trend={data} />
            })}

          </div>}
        </div>
      </div>
    </>
  );
};

export default SparklineChart;
