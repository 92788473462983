import React, { useEffect, useState, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../images/expand-icon.svg';
import download from '../../../../src/images/download-icon.svg';
//import { AppUser } from './../../user/model/AppUser';
import mockData from "./data/production.json";
import Loader from '../../common/page-loader/ComponentLoader';
import NoData from '../../dashboard/components/no-data/NoData';
import ToggleSwitch from '../../common/ToggleSwitch/ToggleSwitch';

interface ProductionChartProps {
    heading: string;
}

interface DataPoint {
    date: number;
    value: number;
}
interface WellProductionTrend {
    gasData: DataPoint[];
    oilData: DataPoint[];
    waterData: DataPoint[];
}
/* interface WellData {
    wellName: string;
    date: string;
    oilData: number;
    waterData: number;
    gasData: number;
    operator:string;
    region:string;
} */


const ProductionDetails: React.FC<ProductionChartProps> = ({ heading }) => {
    const loading = false;  // Set loading based on your data fetching logic
    const [isToggleActive, setIsToggleActive] = useState(false);
    //const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [data, setData] = useState<WellProductionTrend>({ gasData: [], oilData: [], waterData: [] });
    //const [detailsData, setDetailsData] = useState<WellData[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<HTMLDivElement | null>(null);
    //const storedUserData = localStorage.getItem('loggedInUser');
    //const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
    const hElement = document.getElementById('main-root');

    const root = useRef<any>(null);

    const handleToggleChange = (event: any) => {
        event.stopPropagation
        setIsToggleActive(!isToggleActive)
    }

    const getLast7DaysDate = () => {
        const today = new Date();
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 6);
        return last7Days;
    };

    const [selectedDateRange,] = useState({
        startDate: getLast7DaysDate(),
        endDate: new Date(),
        key: 'selection',
    });

    //   function setToStartOfDay(date: any) {
    //     return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    //   }
    //   function setToEndOfDay(date: any) {
    //     return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    //   }
    // Ensure selectedDateRange.startDate is at the start of the day
    //   const startDate = setToStartOfDay(selectedDateRange.startDate);
    //   // Ensure selectedDateRange.endDate is at the end of the day
    //   const endDate = setToEndOfDay(selectedDateRange.endDate);

    const fetchData = async () => {
        const transformedData: WellProductionTrend = {
            gasData: mockData.wellProductionTrend.gasData.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            oilData: mockData.wellProductionTrend.oilData.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            waterData: mockData.wellProductionTrend.waterData.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
        };
        setData(transformedData);
    }

    useEffect(() => {
        fetchData();
    }, [selectedDateRange.startDate, selectedDateRange.endDate]);


    /* const transformedDetails:WellData[]= mockData.wellProductionTrendDetails.map((item) => {return item});
    setDetailsData(transformedDetails); */

    const initialMap = async () => {
        if (chartRef.current) {
            chartRef.current.innerHTML = ''; // Clear the previous chart's HTML
        }

        if (!chartRef.current) return;
        if (chartRef.current) {

            // Create a container for the chart and legend
            root.current = am5.Root.new(chartRef.current);
            // const root = am5.Root.new(chartRef.current);
            root.current.setThemes([am5themes_Animated.new(root.current)]);

            // Create a main container to hold both the chart and the legend
            const mainContainer = root.current.container.children.push(
                am5.Container.new(root.current, {
                    layout: root.current.verticalLayout,
                    width: am5.percent(100),
                    height: am5.percent(100),
                })
            );

            // Create the chart container
            const chartContainer = mainContainer.children.push(
                am5.Container.new(root.current, {
                    width: am5.percent(100),
                    height: am5.percent(85), // Adjust height to leave space for the legend
                })
            );


            const chart = chartContainer.children.push(
                am5xy.XYChart.new(root.current, {
                    focusable: true,
                    panX: false,
                    panY: false,
                    wheelY: 'none',
                    wheelX: 'none',
                    pinchZoomX: false,
                    pinchZoomY: false,
                    paddingBottom: 0,
                }),
            );

            // Additionally, you can also disable zoomOutButton
            chart.zoomOutButton.set("forceHidden", true);

            const xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root.current, {
                    maxDeviation: 0.1,
                    groupData: false,
                    extraMax: 0,
                    extraMin: 0,
                    /* min: startDate.getTime(),
                    max: endDate.getTime(), */
                    baseInterval: { timeUnit: 'day', count: 1 },
                    renderer: am5xy.AxisRendererX.new(root.current, {
                        minGridDistance: 50,
                        minorGridEnabled: true,
                        stroke: am5.color('#487aa6'),
                    }),
                    dateFormats: {
                        day: 'MM/dd',
                        week: 'MM/dd',
                        month: 'MMM',
                        year: 'yyyy',
                    },
                    periodChangeDateFormats: {
                        day: 'MM/dd',
                        week: 'MM/dd',
                        month: 'MMM',
                        year: 'yyyy',
                    },
                    start: 0,
                    end: 1,
                }),
            );
            xAxis.get('renderer').labels.template.set('fill', am5.color('#ffffff'));
            xAxis.get('renderer').labels.template.set('paddingTop', 15);

            const yRenderer = am5xy.AxisRendererY.new(root.current, {
                opposite: false,
                stroke: am5.color('#487aa6'),
            });
            yRenderer.labels.template.set('fill', am5.color('#fff'));
            const yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root.current, {
                    extraMax: 0,
                    extraMin: 0,
                    maxDeviation: 0,
                    renderer: yRenderer,
                }),
            );
            const cursor = chart.set(
                'cursor',
                am5xy.XYCursor.new(root.current, {
                    xAxis: xAxis,
                    yAxis: yAxis,
                    behavior: 'none',
                    showTooltipOn: 'hover',
                }),
            );
            cursor.lineY.set('visible', false);
            cursor.lineX.setAll({
                visible: true,
                stroke: am5.color('#ADD8E6'),
                strokeWidth: 2,
            });

            cursor.lineY.setAll({
                strokeDasharray: [2, 2],
            });
            const createSeries = (data: DataPoint[], strokeColor: string, fillColor: string, name: string) => {
                const series = chart.series.push(
                    am5xy.LineSeries.new(root.current, {
                        name: name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: 'value',
                        valueXField: 'date',
                        tooltip: am5.Tooltip.new(root.current, {
                            pointerOrientation: 'horizontal',
                            labelText: `{name}: {valueY} BBL`,
                        }),
                    }),
                );

                series.set('stroke', am5.color(strokeColor));
                series.set('fill', am5.color(strokeColor));
                series.bullets.push(() => {
                    const circle = am5.Circle.new(root.current, {
                        radius: 0,
                        fill: am5.color('#0000'),
                        stroke: am5.color('#fff'),
                        strokeWidth: 5,
                        visible: true,
                    });

                    circle.states.create('hover', {
                        radius: 7,
                    });

                    return am5.Bullet.new(root.current, {
                        locationX: 0.5,
                        sprite: circle,
                    });
                });

                series.data.setAll(data);

                cursor.events.on('cursormoved', cursorMoved);

                let previousBulletSprites: any = [];
                function cursorMoved() {
                    for (let i = 0; i < previousBulletSprites?.length; i++) {
                        previousBulletSprites[i].unhover();
                    }
                    previousBulletSprites = [];
                    chart.series.each(function (series: any) {
                        const dataItem = series?.get('tooltip')?.dataItem;
                        if (dataItem) {
                            const bulletSprite = dataItem?.bullets?.length && dataItem?.bullets[0]?.get('sprite');
                            bulletSprite && bulletSprite.hover();
                            previousBulletSprites.push(bulletSprite);
                        }
                    });
                }
            }

            createSeries(data.gasData, '#F97066', '#001326', 'Gas');
            createSeries(data.oilData, '#32D583', '#001326', 'Oil');
            createSeries(data.waterData, '#4294FF', '#001326', 'Water');

            const resizeObserver = new ResizeObserver(() => {
                chart.appear(0, 0);
            });

            resizeObserver.observe(chartRef.current);


            // Create the legend container below the chart
            const legendContainer = mainContainer.children.push(
                am5.Container.new(root.current, {
                    width: am5.percent(100),
                    height: am5.percent(15), // Adjust height to match the space left by the chart
                    layout: root.current.verticalLayout,
                })
            );

            const legend = legendContainer.children.push(
                am5.Legend.new(root.current, {
                    layout: am5.GridLayout.new(root.current, {
                        maxColumns: 3,
                        fixedWidthGrid: true,
                    }),
                    centerX: am5.percent(100),
                    x: am5.percent(50),
                    setStateOnChildren: false,
                    paddingTop: 5,
                    useDefaultMarker: true,
                })
            );

            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 10,
                cornerRadiusTR: 10,
                cornerRadiusBL: 10,
                cornerRadiusBR: 10,
                width: 12,
                height: 12,
            });

            legend.labels.template.set('fill', am5.color(0xffffff));
            legend.data.setAll(chart.series.values);
            legend.itemContainers.template.states.create('hover', {});

            return () => {
                root.current.dispose();
                resizeObserver.disconnect();
            };
        }
    }

    useEffect(() => {
        const getEditStatus = setTimeout(async () => {
            await initialMap();
        }, 1000);
        return () => clearTimeout(getEditStatus);
    }, [data]);

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        //setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

    /*  const handleClose = () => {
         //setModalIsOpen(false);
         hElement && hElement?.classList.remove('modal-open');
     }; */

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <ToastContainer position='top-right' autoClose={3000} />
            <div className='grid-item-text h-full graph-card well-graph-card'>
                <div className='table-header-section pie-section'>
                    <div className='title'>{heading}</div>
                    <div className='header-icon'>
                        <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                            <img src={annotation_plus} alt='Expand' className='img-border' />
                        </button>
                    </div>
                    <div className='mr-4 dropdown-container' ref={dropdownRef}>
                        <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span className='dot'></span>
                            <span className='dot'></span>
                            <span className='dot'></span>
                        </button>
                        {isDropdownOpen && (
                            <div className='dropdown-options'>
                                <button>Actions</button>
                                <button className={`btn btn-default cancelSelectorName`}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download PDF
                                </button>
                                <button className={`btn btn-default cancelSelectorName`}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download CSV
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <hr />
                <div id="wellProductionTrend-container" className='wellChartSection wellProductionTrendsChartSection'>
                    {loading ? (
                        // <div className='well-production-loader'>
                        <Loader />
                        // </div> 
                    ) : data?.gasData?.length > 0 || data?.oilData?.length > 0 || data?.waterData?.length > 0 ? (
                        <>
                            {/* <div ref={chartRef} id='wellProductionTrendsChartdiv' style={{ height: '100%', minHeight: '280px', maxHeight: '100%', width: '100%' }}></div> */}
                            <div className='switch-block'>
                                <ToggleSwitch checked={isToggleActive} onChange={handleToggleChange} label={'Virtual flow meter'} />
                            </div>
                            <div ref={chartRef} id='wellProductionTrendsChartdiv' style={{ height: '100%', width: '100%' }}></div>
                        </>
                    ) : (
                        <NoData heading='No data found' />
                    )}
                </div>
            </div>
        </>
    );
};

export default ProductionDetails;