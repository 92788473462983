import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import annotation_plus from './../../../images/expand-icon.svg';
import download from '../../../../src/images/download-icon.svg';
import Loader from '../../common/page-loader/ComponentLoader';
import NoData from '../../dashboard/components/no-data/NoData';
import thinArrow from './../../../images/thinArrow.svg';

interface HarmonicChartProps {
    heading: string;
}

const HarmonicDistortionDetails: React.FC<HarmonicChartProps> = ({heading}) => {
    const loading = false;  // Set loading based on your data fetching logic
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const hElement = document.getElementById('main-root');
    const harmonicRef = useRef<HTMLDivElement>(null);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedItem, setSelecteditem] = useState('Input');

    const selectOptions = ['Input', 'Harmonic', 'distortion'];
   
    const toggleDropdwon = () => {
        setIsSelectOpen(!isSelectOpen);
    };  
    const handleItemClick = (item: string) => {
        setSelecteditem(item);
        setIsSelectOpen(!isSelectOpen);
      };
    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

   /*  const handleClose = () => {
        //setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    }; */

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsDropdownOpen(false);
        }

        if (harmonicRef.current && !harmonicRef.current.contains(event.target as Node)) {
            setIsSelectOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

  return (
    <>
        <ToastContainer position='top-right' autoClose={3000} />
        <div className='grid-item-text h-full graph-card well-graph-card'>
            <div className='table-header-section pie-section'>
                <div className='title'>{heading}</div>
                <div className='header-icon'>
                    <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                        <img src={annotation_plus} alt='Expand' className='img-border' />
                    </button>
                </div>
                <div className='mr-4 dropdown-container' ref={dropdownRef}>
                    <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <span className='dot'></span>
                        <span className='dot'></span>
                        <span className='dot'></span>
                    </button>
                    {isDropdownOpen && (
                    <div className='dropdown-options'>
                        <button>Actions</button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download PDF
                        </button>
                        <button className={`btn btn-default cancelSelectorName`}>
                            <img src={download} alt='icon' className='btn-icon' />
                            Download CSV
                        </button>
                    </div>
                    )}
                </div>
            </div>
            <hr />
            <div id="harmonicDistortion-container" className='wellChartSection harmonicDistortionChartSection'>
                {loading ? (
                    // <div className='well-production-loader'>
                    <Loader />
                    // </div> 
                ) : (
                    <>
                    <div className='flex justify-between items-center'>
                        <div className='well-performance-trends__dropdown' ref={harmonicRef}>
                            <div className='well-performance-trends__dropdown-input-container mt-3' onClick={toggleDropdwon}>
                            <input type='text' className='well-performance-trends__dropdown-input' value={selectedItem} readOnly />
                            <img
                                src={thinArrow}
                                alt={isSelectOpen ? 'Up Arrow' : 'Down Arrow'}
                                className={`mr-3 ${isSelectOpen ? 'rotate-180' : ''}`}
                            />
                            </div>
                            {isSelectOpen && (
                            <ul className='well-performance-trends__dropdown-list'>
                                {selectOptions.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleItemClick(item)}
                                    className='well-performance-trends__dropdown-item'
                                >
                                    {item}
                                </li>
                                ))}
                            </ul>
                            )}
                        </div>
                        <div className='flex justify-end mt-3 space-x-4'>
                            <label className={`radio-container mr-4`} >
                                <input 
                                type='radio' 
                                name={`harmonicCheck`}
                                //onChange={}
                                />
                                <span className='radio-checkmark'></span>
                                Genset
                            </label>
                            <label className={`radio-container`} >
                                <input 
                                type='radio' 
                                name={`harmonicCheck`}
                                //onChange={}
                                />
                                <span className='radio-checkmark'></span>
                                Line Supply
                            </label>
                        </div>
                    </div>
                        {/*<div ref={chartRef} id='wellProductionTrendsChartdiv' style={{ height: '100%', width: '100%' }}></div>*/}                   
                    </>
                )}
                {!modalIsOpen && <NoData heading='No data found' />}
            </div>  
        </div>
    </>    
  );
};

export default HarmonicDistortionDetails;