import React, { useState } from 'react'
import './BuildWatchlistFiltersModal.scss'
import CloseIcon from '../../../../../images/CloseIcon.svg'
import TrashIcon from '../../../../../images/trash-03.svg'
import ArrowUp from '../../../../../images/arrow-up-4.svg'
import ArrowDown from '../../../../../images/arrow-down-4.svg'

interface BuildWatchlistFiltersModalProps {
    closeModal: () => void;
    CreateBtnHandler: (payload: any) => void; // Pass the payload in the handler
}

interface FilterItem {
    id: number;
    name: string;
    description: string;
    isChecked: boolean;
}

const dummyOperatorsData: FilterItem[] = [
    { id: 1, name: 'Operator 1', description: 'Description 1', isChecked: false },
    { id: 2, name: 'Operator 2', description: 'Description 2', isChecked: false },
    { id: 3, name: 'Operator 3', description: 'Description 3', isChecked: false },
    { id: 4, name: 'Operator 4', description: 'Description 4', isChecked: false },
    { id: 5, name: 'Operator 5', description: 'Description 5', isChecked: false },
    { id: 6, name: 'Operator 6', description: 'Description 6', isChecked: false },
]

const dummyLocationsData: FilterItem[] = [
    { id: 1, name: 'Location 1', description: 'Description 1', isChecked: false },
    { id: 2, name: 'Location 2', description: 'Description 2', isChecked: false },
    { id: 3, name: 'Location 3', description: 'Description 3', isChecked: false },
    { id: 4, name: 'Location 4', description: 'Description 4', isChecked: false },
    { id: 5, name: 'Location 5', description: 'Description 5', isChecked: false },
    { id: 6, name: 'Location 6', description: 'Description 6', isChecked: false },
    { id: 7, name: 'Location 7', description: 'Description 7', isChecked: false },
    { id: 8, name: 'Location 8', description: 'Description 8', isChecked: false },
    { id: 9, name: 'Location 9', description: 'Description 9', isChecked: false },
    { id: 10, name: 'Location 10', description: 'Description 10', isChecked: false },
    { id: 11, name: 'Location 11', description: 'Description 11', isChecked: false },
    { id: 12, name: 'Location 12', description: 'Description 12', isChecked: false },
    { id: 13, name: 'Location 13', description: 'Description 13', isChecked: false },
    { id: 14, name: 'Location 14', description: 'Description 14', isChecked: false },
    { id: 15, name: 'Location 15', description: 'Description 15', isChecked: false },
    { id: 16, name: 'Location 16', description: 'Description 16', isChecked: false },
    { id: 17, name: 'Location 17', description: 'Description 17', isChecked: false },
    { id: 18, name: 'Location 18', description: 'Description 18', isChecked: false },
    { id: 19, name: 'Location 19', description: 'Description 19', isChecked: false },
    { id: 20, name: 'Location 20', description: 'Description 20', isChecked: false },
]

const dummyStatusData: FilterItem[] = [
    { id: 1, name: 'Status 1', description: 'Description 1', isChecked: false },
    { id: 2, name: 'Status 2', description: 'Description 2', isChecked: false },
]

const dummyParametersData: FilterItem[] = [
    { id: 1, name: 'Parameter 1', description: 'Description 1', isChecked: false },
    { id: 2, name: 'Parameter 2', description: 'Description 2', isChecked: false },
    { id: 3, name: 'Parameter 3', description: 'Description 3', isChecked: false },
    { id: 4, name: 'Parameter 4', description: 'Description 4', isChecked: false },
    { id: 5, name: 'Parameter 5', description: 'Description 5', isChecked: false },
    { id: 6, name: 'Parameter 6', description: 'Description 6', isChecked: false },
]

const BuildWatchlistFiltersModal = ({ closeModal, CreateBtnHandler }: BuildWatchlistFiltersModalProps) => {
    const [operators, setOperators] = useState<FilterItem[]>(dummyOperatorsData)
    const [locations, setLocations] = useState<FilterItem[]>(dummyLocationsData)
    const [status, setStatus] = useState<FilterItem[]>(dummyStatusData)
    const [parameters, setParameters] = useState<FilterItem[]>(dummyParametersData)

    const [expandedFilters, setExpandedFilters] = useState({
        operators: false,
        location: false,
        status: false,
        parameters: false,
    })

    const [selectedFilters, setSelectedFilters] = useState({
        operators: [] as FilterItem[],
        locations: [] as FilterItem[],
        status: [] as FilterItem[],
        parameters: [] as FilterItem[]
    });

    const updateSelectedFilters = (items: FilterItem[], type: 'operators' | 'locations' | 'status' | 'parameters') => {
        const selectedItems = items.filter(item => item.isChecked);
        setSelectedFilters(prev => ({
            ...prev,
            [type]: selectedItems,
        }));
    };

    const handleCheckboxChange = (id: number, isChecked: boolean, setItems: React.Dispatch<React.SetStateAction<FilterItem[]>>, type: 'operators' | 'locations' | 'status' | 'parameters') => {
        setItems(items => {
            const updatedItems = items.map(item => ({
                ...item,
                isChecked: item.id === id ? isChecked : item.isChecked,
            }));
            updateSelectedFilters(updatedItems, type);
            return updatedItems;
        });
    };

    const handleSelectAll = (setItems: React.Dispatch<React.SetStateAction<FilterItem[]>>, select: boolean, type: 'operators' | 'locations' | 'status' | 'parameters') => {
        setItems(items => {
            const updatedItems = items.map(item => ({
                ...item,
                isChecked: select,
            }));
            updateSelectedFilters(updatedItems, type);
            return updatedItems;
        });
    };
    const toggleFilterSection = (section: 'operators' | 'location' | 'status' | 'parameters') => {
        setExpandedFilters(prev => ({
            operators: section === 'operators',
            location: section === 'location',
            status: section === 'status',
            parameters: section === 'parameters',
            [section]: !prev[section],
        }));
    };

    // const handleCreateBtnClick = () => {
    //     // Create the payload
    //     const payload = {
    //         operators: selectedFilters.operators,
    //         locations: selectedFilters.locations,
    //         status: selectedFilters.status,
    //         parameters: selectedFilters.parameters,
    //     };
    //     CreateBtnHandler(payload);
    // };


    const renderFilterCheckboxes = (items: FilterItem[], handleChange: (id: number, checked: boolean) => void) => (
        <div className="filter-checkboxes">
            {items.map(item => (
                <label key={item.id} className="checkbox-container">
                    <input
                        type="checkbox"
                        checked={item.isChecked}
                        onChange={(e) => handleChange(item.id, e.target.checked)}
                    />
                    <span className="checkbox-checkmark"></span>
                    <p>{item.name}</p>
                </label>
            ))}
        </div>
    )
    console.log(selectedFilters, "selectedFilters");

    const getSelectedCount = (items: FilterItem[]): number => {
        return items.filter(item => item.isChecked).length;
    }
    console.log(getSelectedCount(selectedFilters.operators), "getSelectedCount(selectedFilters.operators)");


    return (
        <div className="build-watchlist-filters-modal modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">Build watchlist</h2>
                    <button className="close-btn" onClick={closeModal}>
                        <img src={CloseIcon} alt="close" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="asset-count-div">
                        <p className="asset-count">10</p>
                        <p className="asset-count-label">Total asset</p>
                    </div>

                    <div className="filters-form-container">
                        <div className="watchlist-filters">

                            <div className="watchlist-filters-heading">
                                Criteria
                            </div>

                            {/* Operators Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('operators')}>
                                <div className="watchlist-filters-title">
                                    Operators
                                </div>
                                <div className="arrow-icon">
                                    <img src={expandedFilters.operators ? ArrowUp : ArrowDown} alt="arrow" />

                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters.operators ? 'expanded' : ''}`}>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setOperators, true, "operators")} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setOperators, false, "operators")} className="filter-button">Clear all</div>
                                </div>
                                {renderFilterCheckboxes(operators, (id, checked) => handleCheckboxChange(id, checked, setOperators, "operators"))}
                            </div>

                            {/* Location Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('location')}>
                                <div className="watchlist-filters-title">
                                    Location
                                </div>
                                <div className="arrow-icon">
                                    <img src={expandedFilters.location ? ArrowUp : ArrowDown} alt="arrow" />

                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters.location ? 'expanded' : ''}`}>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setLocations, true, "locations")} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setLocations, false, "locations")} className="filter-button">Clear all</div>
                                </div>
                                {renderFilterCheckboxes(locations, (id, checked) => handleCheckboxChange(id, checked, setLocations, "locations"))}
                            </div>

                            {/* Status Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('status')}>
                                <div className="watchlist-filters-title">
                                    Status
                                </div>
                                <div className="arrow-icon">
                                    <img src={expandedFilters.status ? ArrowUp : ArrowDown} alt="arrow" />

                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters.status ? 'expanded' : ''}`}>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setStatus, true, "status")} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setStatus, false, "status")} className="filter-button">Clear all</div>
                                </div>
                                {renderFilterCheckboxes(status, (id, checked) => handleCheckboxChange(id, checked, setStatus, "status"))}
                            </div>

                            {/* Parameters Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('parameters')}>
                                <div className="watchlist-filters-title">
                                    Parameters
                                </div>
                                <div className="arrow-icon">
                                    <img src={expandedFilters.parameters ? ArrowUp : ArrowDown} alt="arrow" />

                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters.parameters ? 'expanded' : ''}`}>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setParameters, true, "parameters")} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setParameters, false, "parameters")} className="filter-button">Clear all</div>
                                </div>
                                {renderFilterCheckboxes(parameters, (id, checked) => handleCheckboxChange(id, checked, setParameters, "parameters"))}
                            </div>



                        </div>

                        <div className="watchlist-form">
                            <div className="name-description-form-group">
                                <div className="form-group name">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" id="name" className="form-control" placeholder='Selected well' />
                                </div>
                                <div className="form-group description">
                                    <label htmlFor="description" className="form-label">Description (optional)</label>
                                    <input type="text" id="description" className="form-control" placeholder='Description' />
                                </div>
                            </div>
                            <div className="selected-filters-sec">

                                {getSelectedCount(selectedFilters.operators) ? <div className="selected-filter-div">
                                    <h4>Operators</h4>
                                    <div className="count-del-btn">
                                        <p>{getSelectedCount(selectedFilters.operators)}</p>
                                        <button className="del-btn" onClick={
                                            () => {
                                                setOperators(operators.map(item => ({
                                                    ...item,
                                                    isChecked: false
                                                })))
                                                setSelectedFilters({
                                                    ...selectedFilters,
                                                    operators: []
                                                })
                                            }
                                        } >
                                            <img src={TrashIcon} alt="delete" />
                                        </button>
                                    </div>
                                </div> : ""}

                                {getSelectedCount(selectedFilters.locations) ? <div className="selected-filter-div">
                                    <h4>Location</h4>
                                    <div className="count-del-btn">
                                        <p>{getSelectedCount(selectedFilters.locations)}</p>
                                        <button className="del-btn" onClick={
                                            () => {
                                                setLocations(locations.map(item => ({
                                                    ...item,
                                                    isChecked: false
                                                }))
                                                )
                                                setSelectedFilters({
                                                    ...selectedFilters,
                                                    locations: []
                                                })
                                            }
                                        } >
                                            <img src={TrashIcon} alt="delete" />
                                        </button>
                                    </div>
                                </div> : ""}

                                <div className="selected-filter-div">
                                    <h4>Frequency</h4>
                                    <div className="count-del-btn fre-filter form-group">
                                        <div>
                                            <input type="text" id="minFreq" value={"45 Hz"} className="form-control fre-input" placeholder='Selected well' />
                                            <p>-</p>
                                            <input value={"65 Hz"} type="text" id="maxFreq" className="form-control fre-input" placeholder='Selected well' />

                                        </div>
                                        <button className="del-btn">
                                            <img src={TrashIcon} alt="delete" />
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={closeModal} className="btn btn-secondary btn-lg CR-btn">Clear all</button>
                    <button onClick={CreateBtnHandler} className="btn btn-primary btn-lg CR-btn">Save watchlist</button>
                </div>
            </div>
        </div>
    )
}

export default BuildWatchlistFiltersModal
