import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

interface SpeedChartProps {
    root?: any;
    chartID: string;
    chartRef:any;
    classValue?: string;
    minValue:number;
    maxValue:number;
    clockHandValue: number;
    clockHandName: string;
}

const GaugeChart: React.FC<SpeedChartProps> = ({root, chartID, chartRef, classValue, minValue, maxValue, clockHandValue, clockHandName}) => {

    useEffect(() => {
        if (chartRef.current) {
          root.current = am5.Root.new(chartRef.current);
  
          root.current.setThemes([am5themes_Animated.new(root.current)]);
  
          const chart = root.current.container.children.push(
              am5radar.RadarChart.new(root.current, {
                  panX: false,
                  panY: false,
                  startAngle: -220,
                  endAngle: 40,
                  //innerRadius: -10
              })
          );
  
          const axisRenderer = am5radar.AxisRendererCircular.new(root.current, {
              strokeOpacity: 0.1,
              minGridDistance: 30
          });
  
          axisRenderer.ticks.template.setAll({
              visible: false,
              strokeOpacity: 0.5
          });
  
          axisRenderer.grid.template.setAll({
              visible: false
          });
  
          const axis = chart.xAxes.push(
              am5xy.ValueAxis.new(root.current, {
                  maxDeviation: 0,
                  min: minValue,
                  max: maxValue,
                  strictMinMax: true,
                  renderer: axisRenderer,
                  //fill: am5.color(0x384252),
              })
          );
  
          axis.get('renderer').labels.template.set('fill', am5.color('#fff'));
  
          // create inner gray band.
            const createInnerRange = (start:number, end:number, color:am5.Color) => {
              const rangeDataItem: any = axis.makeDataItem({
                value: start,
                endValue: end
              });
            
              axis.createAxisRange(rangeDataItem);
    
              rangeDataItem.get("axisFill").setAll({
                visible: true,
                fill: color,
                fillOpacity: 1,
                innerRadius: -25,
                  //stroke: color,
                  //  strokeOpacity: 0.8,
                //strokeWidth: 1
              });
              
              rangeDataItem.get("tick").setAll({
                visible: false
              });
            }
    
            createInnerRange(50, 350, am5.color(0x384252));
  
  
          // Create axis ranges bands
          const bandsData = [{
              title: "Volatile",
              color: "#F97066",
              lowScore: 50,
              highScore: 75
            }, {
              title: "Foundational",
              color: "#FDB022",
              lowScore: 75,
              highScore: 100
            }, {
              title: "Developing",
              color: "#12B76A",
              lowScore: 100,
              highScore: 1175
            }, {
              title: "Maturing",
              color: "#12B76A",
              lowScore: 175,
              highScore: 225
            }, {
              title: "Sustainable",
              color: "#FDB022",
              lowScore: 225,
              highScore: 300
            }, {
              title: "High Performing",
              color: "#F97066",
              lowScore: 300,
              highScore: 350
            }];
    
            am5.array.each(bandsData, function (data) {
              const axisRange: any = axis.createAxisRange(axis.makeDataItem({}));
            
              axisRange.setAll({
                value: data.lowScore,
                endValue: data.highScore
              });
            
              axisRange.get("axisFill").setAll({
                visible: true,
                fill: am5.color(data.color),
                fillOpacity: 1,
                innerRadius: -10,
                gap: 10
              });
            
              /* axisRange.get("label").setAll({
                text: data.title,
                inside: true,
                radius: 15,
                fontSize: "0.9em",
                fill: root.interfaceColors.get("background")
              }); */
            });
  
  
  
          // Add clock hand
              const createHand = (value:number, topWidth:number, bottomWidth:number, pinRadius:number, radius:number,  color:am5.Color) => {
                const handDataItem: any = axis.makeDataItem({
                  value: value
                });
                
                const hand = handDataItem.set("bullet", am5xy.AxisBullet.new(root.current, {
                  sprite: am5radar.ClockHand.new(root.current, {
                    topWidth: topWidth,
                    pinRadius: am5.percent(pinRadius),
                    radius: am5.percent(radius),
                    bottomWidth: bottomWidth,
                    //bottomWidth: 200,
                    //innerRadius: am5percent(50),
                  })
                }));
                
                hand.get("sprite").pin.setAll({
                    forceHidden: false,
                    fill: color,
                    fillOpacity: 1
                  });
                
                  hand.get("sprite").hand.setAll({
                    fill: color,
                    fillOpacity: 1
                  });
                
                  
                  axis.createAxisRange(handDataItem);
                
                return hand;
              }
              createHand(50, 0, 0, 72, 78, am5.color(0x5D6675));
              createHand(50, 0, 0, 70, 78, am5.color(0x001023));            
              const hand2 = createHand(clockHandValue, 0, 45, 61, 86, am5.color(0x4A5463));
              const hand1 = createHand(clockHandValue, 0, 40, 60, 85, am5.color(0x001023));
              
  
              hand1.get("sprite").dataItem.animate({
                key: "value",
                to: clockHandValue,
                duration: 800,
                easing: am5.ease.out(am5.ease.cubic)
              }); 
              hand2.get("sprite").dataItem.animate({
                key: "value",
                to: clockHandValue,
                duration: 800,
                easing: am5.ease.out(am5.ease.cubic)
              }); 
              
              const label = chart.radarContainer.children.push(am5.Label.new(root.current, {
                fill: am5.color(0xffffff),
                centerX: am5.percent(50),
                textAlign: "center",
                centerY: am5.percent(50),
                fontSize: "23px",
                fontWeight: "700"
              }));
              label.set("text", Math.round(clockHandValue).toString());
  
              const label2 = chart.radarContainer.children.push(am5.Label.new(root.current, {
                fill: am5.color(0xffffff),
                centerX: am5.percent(50),
                textAlign: "center",
                centerY: am5.percent(-30),
                fontSize: "12px"
              }));
              label2.set("text", `${clockHandName}`);
              
              //hand1.get("grid").set("visible", false);
              //hand1.get("tick").set("visible", false);
          
  
          /* setInterval(() => {
            hand1.get("sprite").dataItem.animate({
              key: "value",
              to: Math.random() * hand1.axis.get("max"),
              duration: 800,
              easing: am5.ease.out(am5.ease.cubic)
            });
            hand2.get("sprite").dataItem.animate({
              key: "value",
              to: Math.random() * hand1.axis.get("max"),
              duration: 800,
              easing: am5.ease.out(am5.ease.cubic)
            });
          }, 2000);
   */
          // Clean up chart when the component unmounts
          return () => {
            root.current.dispose();
          };
        }
      }, []); 
  

    return (
        <>
            <div id={`${chartID}`} ref={chartRef} className={`${classValue} chart-wrapper`}></div>
        </>
    );
};

export default GaugeChart;