
import React from 'react';
import Featured from '../../../../../images/featured-icon.svg';

interface ErrorModalProps {
    isErrorModal: boolean;
    onClose: () => void;
    successCount?: number;
    totalCount?: number;
    fileName?: string;
}

const DownloadError: React.FC<ErrorModalProps> = ({ isErrorModal, onClose, successCount = 0, totalCount, fileName }) => {
    const failedCount = (totalCount && successCount) ? totalCount - successCount : 0
    return (
        <>
            {isErrorModal && (
                <div className='modal-overlay delete-modal-page'>
                    <div className='modal'>
                        <div className='flex flex-row flex-grow profile-block'>
                            <div className='notify-card'>
                                <div className='header-delete'>
                                    <div className='feature-img'>
                                        <img src={Featured} alt='' />
                                    </div>
                                    <p className='title'>Download error</p>
                                    <p className='normal-text'>{`We encountered an error downloading the following file${failedCount && failedCount > 1 ? 's' : ''}. Please refresh your browser and try again.`}</p>
                                    {
                                        fileName ?
                                            <div className='file-list'>
                                                <ul>
                                                    <li className='mt-5'>{fileName}</li>
                                                </ul>
                                            </div>
                                            : ''
                                    }

                                    {totalCount ?
                                        <p className='normal-text mt-5'>{`${successCount} of ${totalCount} files successful`}</p>
                                        : ''
                                    }

                                </div>
                                <div className='flex justify-center pb-4'>
                                    <button onClick={onClose} className='btn btn-primary'>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DownloadError;