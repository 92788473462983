import React, { useState } from 'react';
import CardLayout from '../layout/CardLayout';
import BuildWatchListModal from './buildWatchlistModal/BuildWatchListModal';
import AllWells from './all-wells-component/AllWells';
import WatchWellsList from './wellCardList/WellCardList';
import BuildWatchlistFiltersModal from './buildWatchlistFiltersModal/BuildWatchlistFiltersModal';
import './ControlRoomWells.scss';

const ControlRoomWells = () => {
    const [isBuildWatchlistModalOpen, setIsBuildWatchlistModalOpen] = useState(false);
    const [isBuildWatchlistFiltersModalOpen, setIsBuildWatchlistFiltersModalOpen] = useState(false);

    const toggleModal = (modalType: 'watchlist' | 'filters', isOpen: boolean) => {
        if (modalType === 'watchlist') {
            setIsBuildWatchlistModalOpen(isOpen);
        } else if (modalType === 'filters') {
            setIsBuildWatchlistFiltersModalOpen(isOpen);
        }
    };

    const handleCreateWatchlist = () => {
        toggleModal('watchlist', false);
        toggleModal('filters', true);
    };

    const handleCreateWatchlistFilters = () => {
        toggleModal('filters', false);
    };

    return (
        <div className='control-room-well'>
            <CardLayout
                headerRight={
                    <div className='header-right'>
                        <button
                            onClick={() => toggleModal('filters', true)}
                            className='btn btn-primary border border-danger'
                        >
                            <span className='btn-icon'>+</span> Build watchlist
                        </button>
                    </div>
                }
                title='Well watchlists'
                children={<WatchWellsList />}
            />

            {isBuildWatchlistModalOpen && (
                <BuildWatchListModal
                    CreateBtnHandler={handleCreateWatchlist}
                    closeModal={() => toggleModal('watchlist', false)}
                />
            )}

            {isBuildWatchlistFiltersModalOpen && (
                <BuildWatchlistFiltersModal
                    closeModal={() => toggleModal('filters', false)}
                    CreateBtnHandler={handleCreateWatchlistFilters}
                />
            )}

            <AllWells toggleModal={toggleModal} />
        </div>
    );
};

export default ControlRoomWells;
