import React, { memo, useEffect, useRef } from 'react';
import Close from '../../../../images/x-close.svg';
import CustomModal from '../../../dashboard/components/graph/ExpandModel';
import GaugeChart from '../charts/GaugeChart';
import Loader from '../../../common/page-loader/ComponentLoader';
//import NoData from '../../../dashboard/components/no-data/NoData';

interface SpeedChartModelProps {
    isOpen: boolean;
    onRequestClose: () => void;
    heading: string;
  }

const SpeedChartModel: React.FC<SpeedChartModelProps> = ({
    isOpen,
    onRequestClose,
    heading
}) => {
    const loading = false;  // Set loading based on your data fetching logic
    const speedHzChartdiv = useRef<HTMLDivElement | null>(null);
    const speedRpmChartdiv = useRef<HTMLDivElement | null>(null);
    const root = useRef<any>(null);

    useEffect(() => {
        console.log('isOpen:', isOpen); // Check if modal is open when expected
    }, [isOpen]);

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className='modal-header'>
        <div className='flex'>
          <div className='grow'>
            <h3>{heading}</h3>
          </div>
          <div className='flex-none'>
            <button onClick={onRequestClose}>
              <img src={Close} alt='Expand' />
            </button>
          </div>
        </div>
      </div>
      <div className='modal-content'>
        <div className='flex flex-wrap items-center justify-center speedChart-container-dashboard nodata'>
                        
            {loading ? (
                <div className='flex items-center justify-center w-100'>
                    <Loader />
                </div>
            ) : (
            <>
                <GaugeChart root={root} chartID={'speedHzChartdiv'} chartRef={speedHzChartdiv} classValue={'w-[50%]'} minValue={50} maxValue={350} clockHandValue={100} clockHandName={'HZ'}/>
                <GaugeChart root={root} chartID={'speedRpmChartdiv'} chartRef={speedRpmChartdiv} classValue={'w-[50%]'}  minValue={50} maxValue={350} clockHandValue={200} clockHandName={"RPM"}/>
            </>
            )}
            {/*  <NoData heading='No data found' /> */}

        </div>
      </div>
    </CustomModal>
  )
}

export default memo(SpeedChartModel);