import React, { useState, useEffect, useCallback, useRef } from 'react';
import DocumentTable from './components/DocumentTable';
import { AssetDocument } from './model/AssetDocument';
import DocumentModal from './components/DocumentModal';
import { createDocument, downloadDocument, downloadQbAssetDocument, fetchDocument } from './AssetDocumentsSlice';
import ErrorMessage from './documentationError/ErrorMessage';
import { ToastContainer, toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import './AssetDocuments.scss';
import { AppUser } from '../../../user/model/AppUser';
import filtericon from '../../../../images/filter-icon.svg';
import chevronDown from '../../../../images/chevron-down.svg';
import closeIcon from '../../../../images/x-close-2.svg'
import Loader from '../../../common/page-loader/ComponentLoader';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';
import axios from 'axios';
import DownloadError from './documentationError/DownloadError';

interface TabsProps {
  value?: string | undefined;
}

const AssetDocuments: React.FC<TabsProps> = ({ value }) => {
  const wellId = value || '';
  const dispatch = useAppDispatch();
  const uploadHandledRef = useRef(false);
  const [isDashboardDropdownOpen, setIsDashboardDropdownOpen] = useState(false);
  const [uploadDocument, setUploadDocument] = useState<AssetDocument | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [operator, setOperator] = useState<string>('');
  const [assignedCategory, setAssignedCategory] = useState<string>('');
  const [eventDate, setEventDate] = useState<Date | null>(null);
  const [tempSelectedCategories, setTempSelectedCategories] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpenSource, setModalOpenSource] = useState<'upload' | 'dragAndDrop' | null>(null);
  const [selectedDocumentCount, setSelectedDocumentCount] = useState(0);
  const [showFileColumn, setShowFileColumn] = useState(false);
  const timeOutValue = 180000 // 3 minutes

  const [multiDownloadLoading, setMultiDownloadLoading] = useState(false)
  const [isDownloadError, setIsDownloadError] = useState(false)
  const [downloadSuccessCount, setDownloadSuccessCount] = useState(0)
  const [totalDownloadCount, setTotalDownloadCount] = useState(0)
  const [selectedFileName, setSelectedFileName] = useState('')
  const toggleDashboardDropdown = () => {
    setIsDashboardDropdownOpen(!isDashboardDropdownOpen);
  };

  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const { loading, message } = useAppSelector((state) => state?.assetDocument);
  const [errorType, setErrorType] = useState<string | null>(null);
  const assetControlData = useAppSelector((state) => state?.assetControl)

  const closeModal = () => {
    setIsDocumentModalOpen(false);
  };

  const ErrorModal = () => {
    setIsErrorModal(false);
  };

  let errorMessage = '';
  switch (errorType) {
    case 'fileType':
      errorMessage =
        'This file type is not supported. Please try again with one of the following accepted file types: PDF, Excel, and Word.';
      break;
    case 'fileSize':
      errorMessage =
        'The following files exceed our file size limit of 10MB. Please reduce the file size and try again.';
      break;
    case 'fileMaxNum':
      errorMessage = 'The maximum number of files to be uploaded is more than 10.';
      break;
    default:
      errorMessage = message ? message.toString() : '';
  }

  useEffect(() => {
    if (wellId) {
      dispatch(fetchDocument({ userId: storedUser.id, wellId: wellId }));
    }
    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false))
  }, [wellId, assetControlData?.assetControlScanStatus == true]);

  const dashboardOptions = ['Install', 'Pull', 'Design', 'DIFA', 'Service', 'Survey', 'Other'];

  const closeDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const hadleAddDocument = useCallback(() => {
    const newUploadDocument: AssetDocument = {
      userId: storedUser?.id,
      wellId: wellId,
      expires: new Date().toISOString(),
      operator: operator,
      category: assignedCategory,
      eventDate: eventDate,
      files: uploadedFiles,
      updatedAt: new Date().toString(),
      updatedBy: '',
      fileName: '',
      fileUrl: '',
      selected: false,
      source: '',
    };

    setUploadDocument(newUploadDocument);
    setIsDocumentModalOpen(true);
    closeDropdown();
  }, [operator, assignedCategory, eventDate]);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>, category: string) => {
    if (e.target.checked) {
      setTempSelectedCategories([...tempSelectedCategories, category]);
    } else {
      setTempSelectedCategories(tempSelectedCategories.filter((c) => c !== category));
    }
  };

  useEffect(() => {
    const handleDrop = async (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setModalOpenSource('dragAndDrop');
      uploadHandledRef.current = false;
      if (!e.dataTransfer) {
        return;
      }
      const files = e.dataTransfer.files;
      if (files?.length === 0) {
        return;
      }
      if (files?.length > 10) {
        setErrorType('fileMaxNum');
        setIsErrorModal(true);
        return;
      }
      const maxFileSize = 10 * 1024 * 1024; // 10MB
      const isFileSizeValid = Array.from(files).every((file) => file.size <= maxFileSize);
      if (!isFileSizeValid) {
        setErrorType('fileSize');
        setIsErrorModal(true);
        return;
      }
      const allowedTypes = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
      ];

      const allFilesValid = Array.from(files).every((file) => allowedTypes.includes(file.type));

      if (!allFilesValid) {
        setErrorType('fileType');
        setIsErrorModal(true);
        return;
      }
      // Update the uploadDocument state with the dropped files
      setUploadedFiles(Array.from(files));
      hadleAddDocument();
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    document.addEventListener('drop', handleDrop);
    document.addEventListener('dragover', handleDragOver);

    return () => {
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragover', handleDragOver);
    };
  }, [hadleAddDocument, uploadedFiles]);

  useEffect(() => {
    if (!uploadHandledRef.current && uploadDocument) {
      hadleAddDocument();
      uploadHandledRef.current = true;
    }
  }, [uploadDocument]);

  const [selectedDocuments, setSelectedDocument] = useState<AssetDocument[]>([]);

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const files = input.files;
    if (!files?.length) {
      return;
    }
    if (files?.length > 10) {
      setErrorType('fileMaxNum');
      setIsErrorModal(true);
      return;
    }
    const maxFileSize = 10 * 1024 * 1024; // 10MB
    const isFileSizeValid = Array.from(files).every((file) => file.size <= maxFileSize);
    if (!isFileSizeValid) {
      setErrorType('fileSize');
      setIsErrorModal(true);
      return;
    }
    const allowedTypes = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ];

    const allFilesValid = Array.from(files).every((file) => allowedTypes.includes(file.type));

    if (!allFilesValid) {
      setErrorType('fileType');
      setIsErrorModal(true);
      return;
    }
    // Update the uploadDocument state with the dropped files
    setUploadedFiles(Array.from(files));
    hadleAddDocument();
  };

  const handleUpload = () => {
    if (uploadedFiles?.length > 0) {
      const newUploadDocument: AssetDocument = {
        userId: storedUser?.id,
        wellId: wellId,
        expires: new Date().toISOString(),
        operator: operator,
        category: assignedCategory,
        eventDate: eventDate,
        files: uploadedFiles,
        updatedAt: new Date().toString(),
        updatedBy: '',
        fileName: '',
        fileUrl: '',
        selected: false,
        source: '',
      };

      const formData = new FormData();
      formData.append('userId', newUploadDocument.userId);
      formData.append('wellId', newUploadDocument.wellId);
      formData.append('expires', newUploadDocument.expires);
      formData.append('operator', operator);
      formData.append('category', assignedCategory);
      formData.append('eventDate', eventDate ? eventDate?.toISOString() : '');
      Array.from(newUploadDocument.files).forEach((file) => {
        formData.append('files', file);
      });

      dispatch(createDocument(formData))
        .then(() => {
          dispatch(
            fetchDocument({
              userId: storedUser.id,
              wellId: newUploadDocument.wellId,
            }),
          );
          setOperator('');
          setAssignedCategory('');
          setEventDate(null);
          setIsDocumentModalOpen(false);
          setUploadedFiles([]); // Clear the uploaded files after the upload is done
          toast.success('upload successful');
        })
        .catch((error) => {
          // Handle error if necessary
          console.error('Error:', error);
        });
    }
  };

  const handleDownloadSelectedDocuments = async () => {
    if (selectedDocuments?.length === 0) return
    let successCount = 0;
    const totalCount = selectedDocuments?.length
    let timeoutId: ReturnType<typeof setTimeout> | undefined; // Initialize timeoutId as possibly undefined

    try {
      setMultiDownloadLoading(true);
      const cancelTokenSource = axios.CancelToken.source();

      const timeoutPromise = new Promise((_, reject) => {
        timeoutId = setTimeout(() => {
          reject(setIsDownloadError(true));
          setDownloadSuccessCount(successCount)
          setTotalDownloadCount(totalCount)
          cancelTokenSource.cancel('Asset document download is canceled due to timeout');
          setMultiDownloadLoading(false);
          setSelectedFileName('')
        }, timeOutValue);
      });

      const downloadPromises = selectedDocuments.map(async (doc) => {
        let fileData;
        if (doc.source === 'User') {
          const actionResult = await dispatch(downloadDocument({ id: String(doc.id), cancelToken: cancelTokenSource.token }));
          fileData = actionResult.payload;
        } else {
          const response = await dispatch(downloadQbAssetDocument({ wellId: doc.wellId, fileName: doc.fileName, cancelToken: cancelTokenSource.token }));
          if (response.payload) {
            fileData = response.payload;
          }
        }

        // Create URL for file download
        const url = window.URL.createObjectURL(fileData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', doc.fileName); // Use doc.fileName
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up after download
        successCount++; // Increment success count
      });

      await Promise.race([
        Promise.all(downloadPromises), // Resolves if all downloads complete successfully
        timeoutPromise, // Rejects if timeout exceeds before downloads finish
      ]);

      if (timeoutId) {
        clearTimeout(timeoutId); // Clear timeout when downloads are complete
      }
    } catch (error) {
      console.log('Error downloading documents:', error);
    } finally {
      setMultiDownloadLoading(false);
      console.log("Final Success Count:", successCount);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!e.target) return;
      const target = e.target as HTMLElement;
      if (!target.closest('.custom-dropdown')) {
        setIsDashboardDropdownOpen(false);
        setTempSelectedCategories([])
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleFileColumnToggle = () => {
    setShowFileColumn(!showFileColumn); // Step 2: Toggle checkbox value
  };

  const handleCancelFilter = () => {
    setTempSelectedCategories([])
    setSelectedCategories([])
    setIsDashboardDropdownOpen(!isDashboardDropdownOpen);
  }

  const handleApplyFilter = () => {
    setSelectedCategories(tempSelectedCategories)
    setIsDashboardDropdownOpen(!isDashboardDropdownOpen);
  }

  const handleCloseDownloadError = () => {
    setIsDownloadError(false)
  }

  const showDownloadErrorForSingleDocument = (fileName: string) => {
    setIsDownloadError(true)
    setSelectedFileName(fileName)
    setTotalDownloadCount(0)
    setDownloadSuccessCount(0)
  }
  return (
    <>
      <div className='profile-container w-100 py-6 px-8'>
        {loading || multiDownloadLoading ? (<div className='asset-documnet-loader flex items-center justify-center w-100'>
          <Loader />
        </div>) :
          (<div className='asset-documents profile-status-header'>
            <div className='tab-content set-point-wrap'>
              <div className='card profile-card m-0'>
                <div className='header'>
                  <div className='flex flex-row w-100'>
                    <p className='title'>Documentation </p>
                    {/* <p className=''>
                      <button className='dropdown-btn'>
                        <span className='dot'></span>
                        <span className='dot'></span>
                        <span className='dot'></span>
                      </button>
                    </p> */}
                  </div>
                  <div className='divider'></div>
                  <div className='flex flex-row w-100'>
                    <div className='basis-2/5 document-table-filter-container'>
                      <span className='selected-filters-text'>{selectedDocumentCount} selected</span>
                      <span className='selected-category-document-parent'>
                        {
                          selectedCategories.length > 0 && selectedCategories.map((category, index) => (
                            <div key={index} className='selected-category-document'>
                              <span className='filter-test-category'>{category}</span>
                              <button className='close-icon' onClick={() => {
                                setSelectedCategories(selectedCategories.filter((c) => c !== category))
                                setTempSelectedCategories(tempSelectedCategories.filter((c) => c !== category))
                              }}>
                                <img src={closeIcon} alt='' />
                              </button>
                            </div>
                          ))
                        }
                      </span>
                    </div>
                    <div className='basis-3/5 flex justify-end'>
                      <div className='flex place-content-end'>
                        <div className='filename-container'>
                          <label className='checkbox-container'>
                            <input
                              type='checkbox'
                              id='showCheckbox'
                              checked={showFileColumn}
                              onChange={handleFileColumnToggle}
                            />
                            <span className='checkbox-checkmark'></span>
                          </label>
                          <label htmlFor='showCheckbox' className='ml-5'>&nbsp;File name &nbsp;</label>
                        </div>
                        <button className='ico-btn dwnld' onClick={handleDownloadSelectedDocuments}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-6 h-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3'
                            />
                          </svg>
                        </button>
                        <div className='w-80'>
                          <label className='relative block'>
                            <span className='sr-only'>Search</span>
                            <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                              <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                                <path
                                  fill-rule='evenodd'
                                  d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                                  clip-rule='evenodd'
                                ></path>
                              </svg>
                            </span>
                            <input
                              className='custom-text-input'
                              placeholder='Search...'
                              type='text'
                              name='search'
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                          </label>
                        </div>
                        <div className='w-64'>
                          <div className='cust-drop'>
                            <div
                              className={`custom-dropdown ${isDashboardDropdownOpen ? 'open' : ''}`}
                            >
                              <div className='selected-option' onClick={toggleDashboardDropdown}>
                                <img src={filtericon} alt='filter-icon' className='pr-3' />
                                Filter by category <img src={chevronDown} alt='chevron-down' className='pl-2' />
                              </div>
                              {isDashboardDropdownOpen && (
                                <div className='category-checkboxes'>
                                  <p className='category-header'>Filter by category</p>
                                  {dashboardOptions.map((option) => (
                                    <div key={option} className='category-option'>
                                      <label className='checkbox-container'>
                                        <input
                                          type='checkbox'
                                          id={option}
                                          value={option}
                                          checked={tempSelectedCategories.includes(option)}
                                          onChange={(e) => handleCategoryChange(e, option)}
                                        />
                                        <span className='checkbox-checkmark'></span>
                                      </label>
                                      <label htmlFor={option} className='ml-6'>{option}</label>
                                    </div>
                                  ))}
                                  <div className='flex flex-row justify-end gap-3'>
                                    <button className='btn btn-secondary' onClick={handleCancelFilter}>Cancel</button>
                                    <button className='btn btn-primary' onClick={handleApplyFilter}>Apply</button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className='ml-4 upload-containter'>
                            <input type='file' className='upload-field' onChange={onFileSelect} multiple />
                            <button className='btn btn-primary border border-danger'>
                              <span className='btn-icon'>+</span> Upload
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='body user-details-body'>
                  <div className='adminuser-block'>
                    <div className='flex flex-row'>
                      <DocumentTable
                        selectedCategories={selectedCategories}
                        searchTerm={searchTerm}
                        onGetSelectedDocuments={(count) => setSelectedDocumentCount(count)}
                        showFileColumn={showFileColumn}
                        onSelectDocument={setSelectedDocument}
                        showDownloadErrorForSingleDocument={showDownloadErrorForSingleDocument}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
      </div>
      <ToastContainer position='top-right' autoClose={3000} />
      <DocumentModal
        isOpen={isDocumentModalOpen}
        onClose={() => {
          closeModal();
          setModalOpenSource(null); // Reset the modal open source
        }}
        onSave={handleUpload}
        operator={operator}
        setOperator={setOperator}
        assignedCategory={assignedCategory}
        setAssignedCategory={setAssignedCategory}
        eventDate={eventDate !== null ? new Date(eventDate) : null}
        setEventDate={setEventDate}
        showFileInput={modalOpenSource === 'upload'} // Only pass the file selection handler when the modal is opened by the "Upload" button
      />
      {
        isErrorModal &&
        <ErrorMessage isErrorModal={isErrorModal} isErrorModalClose={ErrorModal} errorMessage={errorMessage} />
      }
      {
        isDownloadError &&
        <DownloadError isErrorModal={isDownloadError} onClose={handleCloseDownloadError} totalCount={totalDownloadCount} successCount={downloadSuccessCount} fileName={selectedFileName} />
      }
    </>
  );
};

export default AssetDocuments;
