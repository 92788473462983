import React, { useState, useEffect, useRef } from 'react';
import SparklineChart from './SparklineChart';
import HistoryChart from './SmoothedXLineChart';
import calendarIcon from '../../../../images/calendar-icon.svg';
import { defaultStaticRanges, defineds, formateDate } from '../../../common/DateRangeSelector/DefaultRanges';
import { useDetectClickOutside } from 'react-detect-click-outside';
import DateRangeSelector from '../../../common/DateRangeSelector/DateRangeSelector';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import filterIcon from '../../../../images/filter-icon.svg';
import searchIcon from '../../../../images/search-icon.svg';
import downIcon from '../../../../images/chevron-down.svg';
import { fetchHistoryChartFilterData } from './HistorySlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { HistoryFilterProps } from './HistoryDetails';
import './History.scss';
import { setFinalfiltered } from './HistoryFilterSlice';
import { AppUser } from '../../../user/model/AppUser';
import { fetchAssetDetail } from '../details-table/AssetDetailSlice';
import { ToastContainer } from 'react-toastify';
import { showToaster } from '../../../dashboard/components/asset-location/AssetList';

const History = () => {
  const dispatch = useAppDispatch();
  const { filterData } = useAppSelector((state) => state.history);
  const { finalFilteredValue } = useAppSelector((state) => state?.historyFilter);
  const [selectedOption, setSelectedOption] = useState('Chart');
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startOfDay(addDays(new Date(), -6)),
    endDate: endOfDay(new Date()),
    key: 'selection',
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [filteredValues, setFilteredValues] = useState<string[]>([]);
  const [prevFilteredValues, setPrevFilteredValues] = useState<string[]>([]);

  const [searchfiltered, setSearchFiltered] = useState<HistoryFilterProps[]>([]);
  const [searchInputVal, setSearchInputVal] = useState<string>('');
  const [filteredVal, setFilteredVal] = useState<string[]>([]);
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const toasterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!filterData) {
      dispatch(fetchHistoryChartFilterData())
        .unwrap()
        .then((response: HistoryFilterProps[]) => {
          const filterSelectedData = response?.map((item) => item.paramStandardType);
          // dispatch(setFilteredValues(filterSelectedData));
          setFilteredValues(filterSelectedData);
          setFilteredVal(filterSelectedData);
          setPrevFilteredValues(filterSelectedData)
          setSearchFiltered(response);
        })
        .catch((error) => {
          setFilteredValues([]);
          setPrevFilteredValues([])
          // dispatch(setFilteredValues([]));
          setFilteredVal([]);
          console.error('Failed to fetch Filter data:', error);
        });
    } else {
      const filterSelectedData = filterData?.map((item) => item.paramStandardType);
      if (finalFilteredValue.length) {
        setFilteredValues(finalFilteredValue);
      } else {
        setFilteredValues(filterSelectedData);
      }
      setPrevFilteredValues(filterSelectedData)
      setFilteredVal(filterSelectedData);
      setSearchFiltered(filterData);
    }
  }, []);

  useEffect(() => {
    if (finalFilteredValue.length) {
      setFilteredVal(finalFilteredValue);
      setFilteredValues(finalFilteredValue);
      setPrevFilteredValues(finalFilteredValue)
    }
  }, [finalFilteredValue])

  const isFilterChanged = prevFilteredValues?.some((item) => !filteredValues?.includes(item)) || filteredValues?.some((item) => !prevFilteredValues?.includes(item));


  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'range-calendar-input') return;
    if (showCalendar) {
      setShowCalendar(!showCalendar);
    }
  };
  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });

  const handleFilterOutClick = (e: any) => {
    if (e.target.id === 'delete-btn') return;

    if (showFilterPopup) {
      setFilteredValues([...prevFilteredValues])
      setShowFilterPopup(false);
      setSearchInputVal('')
      setSearchFiltered(filterData ?? []);
    }
  };

  const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });

  const handleSearch = (e: any) => {
    const val = e.target.value;
    setSearchInputVal(val);

    if (val === '') {
      setSearchFiltered(filterData ?? []);
    } else {
      const updatedFilter = filterData?.filter((item) => item.name.toLowerCase().includes(val.toLowerCase())) ?? [];
      setSearchFiltered(updatedFilter);
    }
  };

  const handleClearButton = () => {
    setFilteredValues([]);
    // setFilteredVal([]);
    setSearchFiltered(filterData ?? []);
    setSearchInputVal('');
  };

  const handleFilterDropdownClick = () => {
    setShowFilterPopup(!showFilterPopup);

    if (!showFilterPopup) {
      setSearchFiltered(filterData ?? []);
      setSearchInputVal('');
    }

  };

  const handleApplyButton = () => {
    if (filteredValues?.length === 0) {
      showToaster('Please select atleast one filter', 'error');
      return;
    }
    const updatedTrendsData =
      filterData?.filter((data) => filteredValues?.includes(data?.paramStandardType))?.map((obj) => obj?.paramStandardType) ?? [];
    setFilteredVal(updatedTrendsData);
    setPrevFilteredValues(updatedTrendsData)
    dispatch(setFinalfiltered(updatedTrendsData));
    setShowFilterPopup(false);
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const checkFilterAvl = filteredValues?.includes(value);
    if (checkFilterAvl) {
      const updatedFilteredValues = filteredValues?.filter((filteredValue) => filteredValue !== value);
      setFilteredValues(updatedFilteredValues);
      // dispatch(setFilteredValues(updatedFilteredValues));
    } else {
      // dispatch(addFilterValue(value))
      setFilteredValues((prev) => [...prev, value]);
    }
  };

  useEffect(() => {
    const wellName = selectedAsset?.selectedAssetName ?? '';
    if (wellName === '' || storedUser.id === '') return;
    dispatch(fetchAssetDetail({ userId: storedUser?.id, wellName: wellName }));

    if (filterData) {
      const filterSelectedData = filterData?.map((item) => item.paramStandardType);
      setFilteredValues(filterSelectedData);
      setPrevFilteredValues(filterSelectedData)
      setFilteredVal(filterSelectedData);
      setSearchFiltered(filterData);
    }
  }, [selectedAsset?.selectedAssetName]);

  const handleSparklineClick = () => {
    if (showFilterPopup) {
      setShowFilterPopup(false)
      if (isFilterChanged) {
        setFilteredValues([...prevFilteredValues])
      }
    }
  }

  const handleChartClick = () => {
    if (filterData) {
      const filterSelectedData = filterData?.map((item) => item.paramStandardType);
      setFilteredValues(filterSelectedData);
      setPrevFilteredValues(filterSelectedData)
      setFilteredVal(filterSelectedData);
      setSearchFiltered(filterData);
    }
  }
  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} ref={toasterRef} />

      <div className='flex flex-row flex-grow profile-block w-100 py-6 px-8'>
        <div className='card profile-card m-0 asset-history'>
          <div className='header'>
            <span className='title'>Asset history</span>
          </div>
          <div className='divider'></div>
          <div className='chart-container flex-row pt-3 pr-6'>
            <div className='select-buttons' style={{ marginRight: '23px', background: 'inherit' }}>
              <button
                className={`select-button ${selectedOption === 'Chart' ? 'active' : ''}`}
                style={{ background: 'inherit' }}
                onClick={() => { setSelectedOption('Chart'); handleChartClick() }}
              >
                Chart
              </button>
              <button
                className={`select-button ${selectedOption === 'SparkLines' ? 'active' : ''}`}
                style={{ background: 'inherit' }}
                onClick={() => { setSelectedOption('SparkLines'); handleSparklineClick() }}
              >
                SparkLines
              </button>
            </div>
            {selectedOption === 'Chart' && (
              <>
                <div
                  className='well-performance-trends__input-group'
                  style={{ height: '45px', background: showCalendar ? 'var(--Dark-mode-800, #001023)' : 'inherit' }}
                >
                  <img src={calendarIcon} alt='Range Calendar' />
                  <input
                    id='range-calendar-input'
                    className='input-date'
                    value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
                    onClick={() => setShowCalendar(!showCalendar)}
                  />
                </div>

                <div className='history-container-filter'>
                  <div style={{ position: 'relative', zIndex: '8' }}>
                    <div
                      id='filter-btn'
                      style={{
                        border: showFilterPopup
                          ? '1px solid var(--Dark-mode-400, #4A5463)'
                          : '1px solid var(--Dark-mode-400, #4A5463)',
                        background: showFilterPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFilterDropdownClick();
                      }}
                    >
                      <img src={filterIcon} alt='filterIcon' />
                      Filter {`(${filteredValues?.length})`}
                      <img src={downIcon} className={!showFilterPopup ? '' : 'rotate-180'} alt='upIcon' />
                    </div>

                    {showFilterPopup && (
                      <div className='trends-filter-modal-container' ref={filterRef}>
                        <div className='trends-filter-modal-header'>Add/Remove trends</div>
                        <div className='trends-filter-search history-search relative'>
                          <img src={searchIcon} placeholder='Search for trend' alt='search' className='history-search-icon' />
                          <input
                            type='text'
                            value={searchInputVal}
                            placeholder='Search for trend'
                            onChange={handleSearch}
                            className='history-filter-search'
                          />
                        </div>
                        <div className='trends-filter-modal-body'>
                          {searchfiltered?.map((val) => {
                            return (
                              <>
                                <div className='checkbox-main-container'>
                                  <label className='checkbox-container'>
                                    <input
                                      type='checkbox'
                                      id='showCheckbox'
                                      checked={filteredValues?.includes(val?.paramStandardType)}
                                      onChange={handleCheckbox}
                                      value={val?.paramStandardType}
                                    />
                                    <span className='checkbox-checkmark'></span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{val?.name}&nbsp;
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                        <div className='trends-filter-modal-footer'>
                          <button className='footer-btn' onClick={handleClearButton}>
                            Clear chart
                          </button>
                          <button className='footer-btn' onClick={handleApplyButton}>
                            Apply
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {selectedOption !== 'Chart' && <div style={{ width: '414px' }}></div>}
          </div>
          <div ref={ref} className='history-date-range'>
            {showCalendar && (
              <DateRangeSelector setShowCalendar={setShowCalendar} setSelectedDateRange={setSelectedDateRange} staticRanges={defaultStaticRanges} minDate={defineds?.startOfLastYear} maxDate={defineds?.endOfToday} selectedDateRange={selectedDateRange} />
            )}
          </div>
          <div className='body'>
            {selectedOption === 'Chart' && (
              <>
                <HistoryChart
                  filteredValues={filteredVal}
                  selectedOption={selectedOption}
                  selectedDateRange={selectedDateRange}
                />
              </>
            )}
            {selectedOption === 'SparkLines' && <SparklineChart />}
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
