import React, { useState } from 'react';
import AllWellsTable from './all-wells-table/AllWellsTable';
import './AllWells.scss';

interface AllWellsProps {
  toggleModal: (modalType: 'watchlist' | 'filters', isOpen: boolean) => void;
}


const AllWells = ({ toggleModal }: AllWellsProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <>
      <div className='all-wells-section'>
        <div className='tab-content set-point-wrap'>
          <div className='card profile-card m-0'>
            <div className='header'>
              <div className='flex flex-row w-100'>
                <p className='title'>All Wells </p>
              </div>
            </div>

            <div className='serch-filter-section flex flex-row w-100'>
              <div className='w-80'>
                <label className='relative block'>
                  <span className='sr-only'>Search by well name or operator</span>
                  <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                    <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                      <path
                        fill-rule='evenodd'
                        d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                        clip-rule='evenodd'
                      ></path>
                    </svg>
                  </span>
                  <input
                    className='custom-text-input'
                    placeholder='Search by well name or operator'
                    type='text'
                    name='search'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <div className='body all-wells-table-body'>
              <div className='all-wells-table-block'>
                <div className='flex flex-row'>
                  <AllWellsTable toggleModal={toggleModal} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllWells;
