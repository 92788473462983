import React from 'react';
import chevronLeftIcon from './../../../../images/chevron-left.svg';
import messageIcon from './../../../../images/message-dots-square.svg';
import barChartIcon from './../../../../images/bar-chart-square-plus.svg';
import infocircleIcon from './../../../../images/info-circle.svg';
import refreshIcon from './../../../../images/refresh-cw-002.svg';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { Link } from 'react-router-dom';

interface WellDashboardHeaderProps {
  headerTitle?: string;
}

const WellDashboardHeader: React.FC<WellDashboardHeaderProps> = ({headerTitle}) => {
  const assetControlRoomDrawer = useAppSelector((state) => state.assetControlRoomDrawer);
  return (
    <div className={`${assetControlRoomDrawer?.openState ? 'relative left-0' : 'fixed left-[68px]'} z-50 bg-[#131F2E] top-0 right-0 py-[15px] text-[#F7F9F9] px-8 flex items-center justify-between wellheader`}>
        <div className='wellheader-left'>
          <h3 className='flex items-center justify-start text-[18px] uppercase font-medium text-[#F7F9F9] page-title'>
          <span className='mr-3 p-2 hover:cursor-pointer'>
            
            <Link to='/layout/control-room'>
              <img src={chevronLeftIcon} alt='chevron-left-icon' />
            </Link>

            </span>
            {headerTitle}
            <span className='text-[12px] rounded-[4px] py-[2px] px-[8px] ml-[7px] font-semibold text-center border-[1px] border-[#32D583] text-[#32D583] running-status'>Running, PID</span>
          </h3>
        </div>
        <div className='flex items-center wellheader-right'>
            <span className='ml-2 p-2 hover:cursor-pointer'>
              <img src={messageIcon} alt='message-icon' />
            </span>
            <span className='ml-2 p-2 hover:cursor-pointer'>
              <img src={barChartIcon} alt='barchart-icon' />
            </span>
            <span className='ml-2 p-2 hover:cursor-pointer'>
              <img src={infocircleIcon} alt='info-icon' />
            </span>
            <span className='ml-3 mr-2 linebar'></span>
            <span className='ml-2 p-2 hover:cursor-pointer'>
              <img src={refreshIcon} alt='refresh-icon' />
            </span>
        </div>
    </div>
  )
}

export default WellDashboardHeader;