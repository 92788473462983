import React, { useState } from 'react';
import './wellWatchlistModal.scss';
import CloseIcon from '../../../../../../images/CloseIcon.svg';
import WellWatchListCard from './wellWatchListCard/wellWatchListCard';

interface wellWatchlistModal {
    handleCloseModal: () => void;
    createBtnHandler: () => void;
}

function WellWatchlistModal({ handleCloseModal, createBtnHandler }: wellWatchlistModal) {
  const wellsArray = [
    {
      id: '1',
      name: 'Diamondback wells',
      description: 'My collection of diamondback wells',
      count: 10,
      isActive: false,
    },
    {
      id: '2',
      name: 'Diamondback well review',
      description: 'My collection of wells for upcomming diamondback meetings',
      count: 20,
      isActive: false,
    },
    {
      id: '3',
      name: 'Low Performing wells',
      description: 'My lowest performing wells for tracking',
      count: 30,
      isActive: false,
    },
    {
      id: '4',
      name: 'High performing wells',
      description: 'My highest performing wells for tracking',
      count: 40,
      isActive: false,
    },
    { id: '5', name: 'ADAMS 43-6 B 802JM', description: 'Description 5', count: 50, isActive: false },
    { id: '6', name: 'ALLDALE 136-321 A 2MS', description: 'Description 6', count: 60, isActive: false },
    { id: '7', name: 'ALETHA 4 08LS', description: 'Description 7', count: 70, isActive: false },
    { id: '8', name: 'ABBIE LAINE 30XX 14HC', description: 'Description 8', count: 80, isActive: false },
    { id: '9', name: 'ALLDALE 136-321 B 3JM', description: 'Description 9', count: 90, isActive: false },
    { id: '10', name: 'A CLASSIC DASH 18 1H', description: 'Description 10', count: 100, isActive: false },
    { id: '11', name: 'A HERRING 94-93-2N 12H', description: 'Description 11', count: 110, isActive: false },
    { id: '12', name: 'ORYX ROAN STATE A 1301HWell 12', description: 'Description 12', count: 120, isActive: false },
  ];

  const [wells, setWells] = useState(wellsArray);

  const handleCardClick = (id: string) => {
    const updatedWells = wells.map((well) => ({
      ...well,
      isActive: well.id === id,
    }));

    setWells(updatedWells);
  };

  return (
    <div className='build-watchlist-modal modal-overlay'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2 className='modal-title'>Build watchlist</h2>
          <button className='close-btn' onClick={handleCloseModal}>
            <img src={CloseIcon} alt='close' />
          </button>
        </div>

        <div className='modal-body'>
          <div style={{marginBottom: '20px', display: 'flex', justifyContent: 'right', marginRight: '30px'}}>
             <button onClick={() => createBtnHandler()} className='btn btn-primary border border-danger'> Build watchlist</button>
          </div>
          <div className='well-watch-list'>
            {wells.map((well) => (
              <WellWatchListCard
                key={well.id}
                id={well.id}
                title={well.name}
                description={well.description}
                count={well.count}
                isActive={well.isActive}
                onClick={handleCardClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WellWatchlistModal;
