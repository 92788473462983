import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { HistoryChartProps, ValueData } from './HistoryDetails';
import { fetchHistoryChartDataById } from './HistorySlice';
import { formateDate } from '../../../common/DateRangeSelector/DefaultRanges';
import Loader from '../../../common/page-loader/ComponentLoader';
// import moment from 'moment-timezone';
import NoData from '../../../dashboard/components/no-data/NoData';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';

interface DataProps {
  name: string;
  value: number;
  uom: string;
  short_UOM: string;
  paramTypeId: any;
}

interface ChartData {
  date: string;
  data: DataProps[];
}

interface HistoryChartDataProps {
  selectedOption: string;
  selectedDateRange: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
  filteredValues: string[];
}

const SmoothedXLineChart: React.FC<HistoryChartDataProps> = ({ selectedOption, selectedDateRange, filteredValues }) => {
  const historyChartData = useAppSelector((state) => state.history.historychart as HistoryChartProps[] | null | any);
  const loading = useAppSelector((state) => state.history.historyChartDataLoaing);
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const dispatch = useAppDispatch();
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [showError, setShowError] = useState(false);
  const assetControlData = useAppSelector((state) => state?.assetControl)

  const colorHex: { [key: string]: string } = {
    'System frequency': '#FEC84B',
    'Vibration Y': '#FFD072',
    'DC bus voltage': '#006DFA',
    'Drive voltage': '#12B76A',
    'Vibration X': '#3A7E73',
    'Intake pressure': '#004EB3',
    'Motor temperature': '#F97066',
    'Intake temperature': '#F04438',
    'Casing pressure': '#6BBBAE',
    'Tubing pressure': '#0094BD',
    'Motor current': '#FB743C',
    'Downhole current': '#F97066',
    'System RPM': '#B8C5CC',
  };

  function setToStartOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
  function setToEndOfDay(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
  // Ensure selectedDateRange.startDate is at the start of the day
  const startDate = setToStartOfDay(selectedDateRange.startDate);
  // Ensure selectedDateRange.endDate is at the end of the day
  const endDate = setToEndOfDay(selectedDateRange.endDate);

  useEffect(() => {
    const wellName = selectedAsset.selectedAssetName ?? '';
    if (wellName === '') return;
    const startDate = formateDate(selectedDateRange.startDate);
    const endDate = formateDate(selectedDateRange.endDate);

    if (wellName === '') return;
    dispatch(
      fetchHistoryChartDataById({
        wellName: wellName,
        startDate: startDate,
        endDate: endDate,
      }),
    );
    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false))
  }, [selectedAsset.selectedAssetName, selectedDateRange.startDate, selectedDateRange.endDate, assetControlData?.assetControlScanStatus === true]);

  useEffect(() => {
    if (historyChartData !== null) {
      const data: ChartData[] = [];

      Object.entries(historyChartData).forEach(([key]) => {
        const dataVal = historyChartData[key]
          ?.filter((val: any) => filteredValues.includes(val?.paramTypeId))
          ?.map((item: ValueData) => {
            return {
              name: item?.name ?? '',
              value: Number(item?.value) ?? 0,
              uom: item?.uom ?? '',
              short_UOM: item?.short_UOM ?? '',
              paramTypeId: item?.paramTypeId ?? '',
            };
          });

        const newData = {
          date: key,
          data: dataVal,
        };

        data.push(newData);
      });
      setChartData(data);
    }
  }, [historyChartData, filteredValues]);

  const chartDataValues = chartData.flatMap((item) => item.data.map((dataItem) => dataItem.paramTypeId));
  const uniqueChartDataValues = [...new Set(chartDataValues)];
  const chartDataCommonValues = uniqueChartDataValues.filter((value: any) => filteredValues.includes(value));

  function createSeries(
    chart: am5xy.XYChart,
    root: am5.Root,
    name: string,
    field: string,
    color: string,
    xAxis: am5xy.DateAxis<am5xy.AxisRendererX>,
    yAxis: am5xy.ValueAxis<am5xy.AxisRendererY>,
    data: any[],
    cursor: any,
  ) {
    const series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'left',
          y: am5.percent(50),
          forceHidden: true,
        }),
        snapTooltip: true,
        stroke: am5.color(color),
        fill: am5.color(color),
      }),
    );

    series.strokes.template.set('strokeWidth', 2);

    series.bullets.push(() => {
      const circle = am5.Circle.new(root, {
        radius: 0,
        fill: am5.color('#000'),
        stroke: am5.color('#fff'),
        strokeWidth: 3,
        interactive: true,
        // visible: true,
        opacity: 0,
      });

      circle.states.create('default', {
        opacity: 0,
      });

      circle.states.create('hover', {
        radius: 6,
        opacity: 1,
      });

      return am5.Bullet.new(root, {
        locationX: 0.5,
        sprite: circle,
      });
    });

    cursor.events.on('cursormoved', cursorMoved);

    let previousBulletSprites: any = [];
    function cursorMoved() {
      for (let i = 0; i < previousBulletSprites?.length; i++) {
        previousBulletSprites[i].unhover();
      }
      previousBulletSprites = [];
      chart.series.each(function (series) {
        const dataItem = series?.get('tooltip')?.dataItem;
        if (dataItem) {
          const bulletSprite = dataItem?.bullets?.length && dataItem?.bullets[0]?.get('sprite');
          bulletSprite && bulletSprite.hover();
          previousBulletSprites.push(bulletSprite);
        }
      });

      let show = true;
      let html = `<div style="width:auto;display:flex;flex-direction:column;font-size: 13px;color: #F7F9F9;font-family: 'Mulish';font-weight: 400">`;
      chart.series.each(function (series) {
        const tooltipDataItem = series.get('tooltipDataItem');
        if (tooltipDataItem) {
          if (show) {
            const valueX = tooltipDataItem.get("valueX");
            if (valueX != null) { // Ensure valueX is not null or undefined
              const date: any = new Date(valueX);
              const dateOptions: any = { weekday: 'long', month: 'short', day: 'numeric' };
              const formattedDate = date.toLocaleDateString(undefined, dateOptions);
              const timeOptions: any = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
              const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
              const result = `${formattedDate}, ${formattedTime}`;
              // const d = moment(tooltipDataItem.get('valueX')).format('dddd, MMM D, HH:mm:ss');
              html += `<div style="border-bottom: 1px solid #4A5463;padding:10px"> ${result}</div>`;
              show = false;
            }
          }

          const valueY = tooltipDataItem.get('valueY');
          const seriesName = series.get('name') || 'Unnamed Series';
          const seriesColor = series.get('stroke')?.toString() || '#000000';
          if (series.isVisible() === true) {
            html += `<div style="display: flex; align-items: center;flex-direction: row;gap: 20px;justify-content: space-between;padding:5px">
            <div style="height:24px;align-items: center;display: flex">
            <span style="border:1px solid ${seriesColor};height:24px;margin-right: 5px;"></span>
            <span>${seriesName}</span>
            </div>
            <div>${valueY == null
                ? ''
                : +valueY.toFixed(3) +
                (series.get('name') === 'System frequency'
                  ? ' Hz'
                  : series.get('name') === 'Intake temperature' || series.get('name') === 'Motor temperature'
                    ? ' °F'
                    : series.get('name') === 'Drive voltage' || series.get('name') === 'DC bus voltage'
                      ? ' V'
                      : series.get('name') === 'Intake pressure' ||
                        series.get('name') === 'Tubing pressure' ||
                        series.get('name') === 'Casing pressure'
                        ? ' PSI'
                        : series.get('name') === 'Motor current' || series.get('name') === 'Downhole current'
                          ? ' A'
                          : series.get('name') === 'System RPM'
                            ? ' RPM' : series.get('name') === 'Vibration X' || series.get('name') === 'Vibration Y' ? ' g'
                              : '')
              }</div>
            </div>`;
          }
        }
      });

      html += '</div>';
      chart.plotContainer.set('tooltipHTML', html);
    }

    const sortData = data.sort((a, b) => a.date - b.date);
    series.data.setAll(sortData);
  }

  useLayoutEffect(() => {
    if (!loading && chartDataCommonValues.length > 0) {
      if (historyChartData === null) {
        return;
      }
      const root = am5.Root.new('chartdiv');

      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          wheelY: 'zoomX',
          layout: root.verticalLayout,
          maxTooltipDistance: 0,
          paddingTop: 50,
        }),
      );
      const data = chartData?.map((item) => {
        const date = new Date(item.date).getTime();
        const result: any = { date };
        item.data.forEach((entry) => {
          result[entry.name] = entry.value;
        });
        return result;
      });

      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.2,
          groupData: false,
          extraMax: 0,
          extraMin: 0,
          strictMinMax: true,
          min: startDate.getTime(),
          max: endDate.getTime(),
          baseInterval: { timeUnit: 'minute', count: 1 },
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 100,
            stroke: am5.color('#FFFFFF'),
          }),
        }),
      );

      xAxis.get('renderer').labels.template.set('fill', am5.color('#fff'));
      xAxis.get('renderer').labels.template.set('paddingTop', 20);

      const yAxis1 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: false,
          }),
        }),
      );

      yAxis1.get('renderer').labels.template.set('fill', am5.color('#fff'));

      const yAxis0 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: false,
          }),
        }),
      );

      yAxis0.get('renderer').labels.template.set('fill', am5.color('#fff'));

      if (chartDataCommonValues.includes('1421') || chartDataCommonValues.includes('1422')) {
        const yAxis0Label = am5.Label.new(root, {
          text: 'g',
          fill: am5.color('#fff'),
          rotation: 0,
          centerX: am5.p50,
          centerY: am5.p50,
          x: 15,
          y: -30,
          fontSize: 12,
          fontWeight: 'bold',
        });
        yAxis0.children.push(yAxis0Label);
      }


      const yAxis2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: false,
          }),
        }),
      );

      yAxis2.get('renderer').labels.template.set('fill', am5.color('#fff'));

      if (chartDataCommonValues.includes('230') || chartDataCommonValues.includes('273')) {
        const yAxis2Label = am5.Label.new(root, {
          text: 'A',
          fill: am5.color('#fff'),
          rotation: 0,
          centerX: am5.p50,
          centerY: am5.p50,
          x: 15,
          y: -30,
          fontSize: 12,
          fontWeight: 'bold',
        });
        yAxis2.children.push(yAxis2Label);
      }

      const yAxis3 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: false,
          }),
        }),
      );

      yAxis3.get('renderer').labels.template.set('fill', am5.color('#fff'));

      if (
        chartDataCommonValues.includes('1') ||
        chartDataCommonValues.includes('9') ||
        chartDataCommonValues.includes('10')
      ) {
        const yAxis3Label = am5.Label.new(root, {
          text: 'PSI',
          fill: am5.color('#fff'),
          rotation: 0,
          centerX: am5.p50,
          centerY: am5.p50,
          x: 15,
          y: -30,
          fontSize: 12,
          fontWeight: 'bold',
        });
        yAxis3.children.push(yAxis3Label);
      }

      const yAxis4 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        }),
      );

      yAxis4.get('renderer').labels.template.set('fill', am5.color('#fff'));

      if (chartDataCommonValues.includes('189') || chartDataCommonValues.includes('187')) {
        const yAxis4Label = am5.Label.new(root, {
          text: '°F',
          fill: am5.color('#fff'),
          rotation: 0,
          centerX: am5.p50,
          centerY: am5.p50,
          x: 15,
          y: -30,
          fontSize: 12,
          fontWeight: 'bold',
        });
        yAxis4.children.push(yAxis4Label);
      }

      const yAxis5 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        }),
      );

      yAxis5.get('renderer').labels.template.set('fill', am5.color('#fff'));

      if (chartDataCommonValues.includes('2')) {
        const yAxis5Label = am5.Label.new(root, {
          text: 'Hz',
          fill: am5.color('#fff'),
          rotation: 0,
          centerX: am5.p50,
          centerY: am5.p50,
          x: 15,
          y: -30,
          fontSize: 12,
          fontWeight: 'bold',
        });
        yAxis5.children.push(yAxis5Label);
      }

      const yAxis6 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        }),
      );

      yAxis6.get('renderer').labels.template.set('fill', am5.color('#fff'));

      if (chartDataCommonValues.includes('10003') || chartDataCommonValues.includes('10002')) {
        const yAxis6Label = am5.Label.new(root, {
          text: 'V',
          fill: am5.color('#fff'),
          rotation: 0,
          centerX: am5.p50,
          centerY: am5.p50,
          x: 15,
          y: -30,
          fontSize: 12,
          fontWeight: 'bold',
        });
        yAxis6.children.push(yAxis6Label);
      }

      const yAxis7 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          extraTooltipPrecision: 1,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
        }),
      );

      yAxis7.get('renderer').labels.template.set('fill', am5.color('#fff'));

      if (chartDataCommonValues.includes('10005')) {
        const yAxis7Label = am5.Label.new(root, {
          text: 'RPM',
          fill: am5.color('#fff'),
          rotation: 0,
          centerX: am5.p50,
          centerY: am5.p50,
          x: 15,
          y: -30,
          fontSize: 12,
          fontWeight: 'bold',
        });
        yAxis7.children.push(yAxis7Label);
      }

      const cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          yAxis: yAxis1,
          behavior: 'selectXY',
          showTooltipOn: 'hover',
        }),
      );
      cursor.lineY.set('visible', false);
      cursor.lineX.setAll({
        visible: true,
        stroke: am5.color('#ADD8E6'),
        strokeWidth: 2,
      });

      cursor.lineY.setAll({
        strokeDasharray: [2, 2],
      });

      const tooltip: any = am5.Tooltip.new(root, {
        pointerOrientation: 'right',
        dx: 15,
      });

      tooltip.get('background').setAll({
        fill: am5.color('#001023'),
        stroke: am5.color('#4A5463'),
        opacity: 1,
      });

      chart.plotContainer.set('tooltipPosition', 'pointer');
      chart.plotContainer.set('tooltipHTML', '<div >' + 'Placeholder' + '</div>');
      chart.plotContainer.set('tooltip', tooltip);

      chartData[0]?.data?.forEach((element: any) => {
        const colordata = colorHex[element.name] ? colorHex[element.name] : '#60BFDA';
        if (element.short_UOM.toLowerCase() === 'a') {
          createSeries(
            chart,
            root,
            element?.name,
            element?.name,
            colordata,
            xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
            yAxis2 as am5xy.ValueAxis<am5xy.AxisRendererY>,
            data,
            cursor,
          );
        } else if (element.short_UOM.toLowerCase() === 'psi') {
          createSeries(
            chart,
            root,
            element?.name,
            element?.name,
            colordata,
            xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
            yAxis3 as am5xy.ValueAxis<am5xy.AxisRendererY>,
            data,
            cursor,
          );
        } else if (element.short_UOM.toLowerCase() === '°f') {
          createSeries(
            chart,
            root,
            element?.name,
            element?.name,
            colordata,
            xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
            yAxis4 as am5xy.ValueAxis<am5xy.AxisRendererY>,
            data,
            cursor,
          );
        } else if (element.short_UOM.toLowerCase() === 'hz') {
          createSeries(
            chart,
            root,
            element?.name,
            element?.name,
            colordata,
            xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
            yAxis5 as am5xy.ValueAxis<am5xy.AxisRendererY>,
            data,
            cursor,
          );
        } else if (element.short_UOM.toLowerCase() === 'v') {
          createSeries(
            chart,
            root,
            element?.name,
            element?.name,
            colordata,
            xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
            yAxis6 as am5xy.ValueAxis<am5xy.AxisRendererY>,
            data,
            cursor,
          );
        } else if (element.short_UOM.toLowerCase() === 'rpm') {
          createSeries(
            chart,
            root,
            element?.name,
            element?.name,
            colordata,
            xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
            yAxis7 as am5xy.ValueAxis<am5xy.AxisRendererY>,
            data,
            cursor,
          );
        } else if (element.short_UOM.toLowerCase() === 'g') {
          createSeries(
            chart,
            root,
            element?.name,
            element?.name,
            colordata,
            xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
            yAxis0 as am5xy.ValueAxis<am5xy.AxisRendererY>,
            data,
            cursor,
          );
        } else {
          createSeries(
            chart,
            root,
            element?.name,
            element?.name,
            colordata,
            xAxis as am5xy.DateAxis<am5xy.AxisRendererX>,
            yAxis1 as am5xy.ValueAxis<am5xy.AxisRendererY>,
            data,
            cursor,
          );
        }
      });

      const legend = chart.children.push(
        am5.Legend.new(root, {
          x: am5.percent(50),
          centerX: am5.percent(50),
          layout: am5.GridLayout.new(root, {
            maxColumns: 6,
            fixedWidthGrid: true,
          }),
          setStateOnChildren: false,
          paddingTop: 15,
          useDefaultMarker: true,
        }),
      );

      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 12,
        height: 12,
      });

      legend.labels.template.set('fill', am5.color(0xffffff));
      legend.data.setAll(chart.series.values);
      legend.itemContainers.template.states.create('hover', {});

      return () => {
        root.dispose();
      };
    }
  }, [dispatch, selectedOption, historyChartData, chartData]);

  useEffect(() => {
    if ((!historyChartData || chartDataCommonValues.length === 0) && !loading) {
      const timer = setTimeout(() => {
        setShowError(true);
      }, 500);

      return () => clearTimeout(timer);
    } else {
      setShowError(false);
    }
  }, [chartDataCommonValues, loading]);

  return (
    <div className='history-chart-container' style={{ minHeight: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {loading ? (
        <div className='chart-loader'>
          <Loader />
        </div>
      ) : (historyChartData && chartDataCommonValues.length > 0) ? (
        <div id='chartdiv' style={{ width: '100%', height: '500px' }}></div>
      ) : (
        showError && (
          <div className='flex flex-column items-center justify-center'>
            <NoData heading='No data found' />
          </div>
        )
      )}
    </div>
  );
};

export default SmoothedXLineChart;
